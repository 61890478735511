import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import { getTblStore, getTblMaterial } from "store/actions";
import FilteredByStock from "./FilteredByStock";
import FilteredByMaterial from "./FilteredByMaterial";
import Breadcrumbs from "components/Common/Breadcrumb";

const StockBalance = (props) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("FilterByStock");

  useEffect(() => {
    if (activeTab === "FilterByStock") {
      dispatch(getTblStore());
    } else {
      dispatch(getTblMaterial());
    }
  }, [activeTab]);

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Shift")}
            breadcrumbItem={props.t("Stock Balance")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <ul className="nav nav-tabs nav-tabs-custom">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "FilterByStock",
                        })}
                        onClick={() => {
                          toggleTab("FilterByStock");
                        }}
                      >
                        {props.t("Filter By Stock")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "FilterByMaterial",
                        })}
                        onClick={() => {
                          toggleTab("FilterByMaterial");
                        }}
                      >
                        {props.t("Filter By Material")}
                      </NavLink>
                    </NavItem>
                  </ul>
                  <div className="mt-4">
                    {activeTab === "FilterByStock" ? (
                      <FilteredByStock />
                    ) : (
                      <FilteredByMaterial />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>);
};

StockBalance.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(StockBalance));
