import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import TableContainer from '../../../components/Common/TableContainer';

import {
    Col,
    Row,
    Card,
    CardBody,
    Spinner
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getSupplierAccount } from "store/reports/actions";
import { tblSupplier } from "store/supplier/actions";
import DateFromTo from "../shared/DateFromTo";
import { convertDate, convertParametersToString } from "../helpers";
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf";
import { selectInputBgColors, layoutModeTypes } from "constants/layout";

const SupplierAccount = (props) => {
    document.title = "Supplier Account | Reports";
    const dispatch = useDispatch();
    const [date, setDate] = useState(null);
    const [supplierData, setSupplierData] = useState([]);
    const [currentSupplier, setcurrentSupplier] = useState({});
    const [currentSupplierId, setcurrentSupplierId] = useState("");
    const [modifiedSupplierAccountData, setModifiedSupplierAccountData] = useState([]);

    const layout = useSelector(state => state.Layout);
    const suppliers = useSelector(state => state.suppliers);
    const reports = useSelector(state => state.Reports);
    const { layoutModeType } = layout;
    const { tblSupplier: tblSupplierData, } = suppliers;
    const {
        getSupplierAccount: getSupplierAccountData,
        loadingSupplierAccount: loading
    } = reports;

    // update modifiedSupplierAccountData
    useEffect(() => {
        if (!getSupplierAccountData.length) return;
        const modifiedData = getSupplierAccountData
            .map((one, i) => {
                const OpeningBalance = i === 0 ? one.OpeningBalance : getSupplierAccountData[i - 1].Balance;
                const paidUpValue = one.PaidUp === 0
                    ? props.t("Deferred purchase invoice")
                    : props.t("Cash Purchase invoice");
                return {
                    ...one,
                    OpeningBalance: OpeningBalance,
                    PaidUp: paidUpValue
                };
            });
        setModifiedSupplierAccountData(modifiedData);
    }, [getSupplierAccountData, getSupplierAccountData.length]);

    useEffect(() => {
        dispatch(tblSupplier());
    }, []);

    useEffect(() => {
        const newSuppliersData = tblSupplierData.map(one => ({ value: one.supplierName, label: one.supplierName, pkSupplierId: one.pkSupplierId }));
        setcurrentSupplier(newSuppliersData[0]);
        if (newSuppliersData && newSuppliersData[0] && newSuppliersData[0].pkSupplierId) {
            setcurrentSupplierId(newSuppliersData[0].pkSupplierId);
        }
        setSupplierData(newSuppliersData);
    }, [tblSupplierData]);

    useEffect(() => {
        let params = { ...date };
        if (currentSupplierId) {
            params.suppID = currentSupplierId;
        }
        const paramters = convertParametersToString(params);
        dispatch(getSupplierAccount(paramters));
    }, [date, currentSupplierId]);

    const getDateFromToHandler = (date) => {
        setDate(date);
    };

    const columns = useMemo(
        () => [
            {
                Header: props.t('Previous Credit'),
                accessor: 'OpeningBalance',
                Cell: (cellProps) => {
                    return <>{cellProps.value || 0}</>;
                }
            },
            {
                Header: props.t('Post Credit'),
                accessor: 'Balance',
                Cell: (cellProps) => {
                    return <>{cellProps.value || 0}</>;
                }
            },
            {
                Header: 'Date',
                accessor: 'LogDate',
                filterable: true,
                Cell: (cellProps) => {
                    const date = new Date(cellProps.value);
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const day = date.getDate();

                    return <>{cellProps.value ? day + "/" + month + "/" + year : ""}</>;
                }
            },
            {
                Header: props.t('Owed to him'),
                accessor: 'Owned',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || 0}</>;
                }
            },
            {
                Header: props.t('Bill Description.'),
                accessor: 'PaidUp',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value}</>;
                }
            },
        ],
        [props.t]
    );

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs
                    title={props.t("Shift")}
                    breadcrumbItem={props.t("Supplier Account")}
                />
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody className="border-bottom ml-2">
                                <Row className="row-cols-auto gap-4 align-items-center">
                                    <DateFromTo getDateFromTo={getDateFromToHandler} />
                                    <div className="row">
                                        <div className="col-md-auto mx-3 mx-lg-0">
                                            <div className="form-group row px-0 align-items-center">
                                                <label className="col-auto col-form-label px-0">{props.t("Supplier")}</label>
                                                <div className="col-auto" style={{ width: "220px" }}>
                                                    <Select
                                                        value={currentSupplier}
                                                        onChange={(selectedOption) => {
                                                            setcurrentSupplier(selectedOption);
                                                            setcurrentSupplierId(selectedOption.pkSupplierId);
                                                        }}
                                                        options={supplierData}
                                                        className="select2-selection form-control p-0 border-0"
                                                        styles={{ menu: base => ({ ...base, backgroundColor: layoutModeType === layoutModeTypes.LIGHT ? selectInputBgColors.light : selectInputBgColors.dark }) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </CardBody>
                            <CardBody>
                                {loading
                                    ? <div className="text-center py-5"><Spinner className="ms-2" color="primary" /></div>
                                    : <TableContainer
                                        columns={columns}
                                        data={modifiedSupplierAccountData}
                                        isJobListGlobalFilter={true}
                                        customPageSize={5}
                                        allListSize={modifiedSupplierAccountData.length || 0}
                                    />
                                }
                            </CardBody>
                            {!loading && modifiedSupplierAccountData.length > 0 ?
                                <div className="mt-2 d-flex justify-content-end">
                                    <SendAndPrintPdf
                                        data={modifiedSupplierAccountData}
                                        extraData={{
                                            "Date_from": date && date.dateFrom ? convertDate(date.dateFrom) : "First",
                                            "Date_to": date && date.dateTo ? convertDate(date.dateTo) : convertDate(new Date()),
                                            "Supplier": currentSupplier && currentSupplier.value ? currentSupplier.value : null,
                                            "Items_no": modifiedSupplierAccountData.length || 0
                                        }}
                                        filteredData={["No", "OpeningBalance", "Balance", "LogDate", "Owned", "PaidUp"]}
                                    />
                                </div>
                                : <></>
                            }
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
};


SupplierAccount.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(SupplierAccount));