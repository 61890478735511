import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_UNITS, POST_UNIT } from "./actionTypes";

import { getUnitsSuccess, getUnitsFail, postUnitSuccess, postUnitFail } from "./actions";

import { getUnits, postUnit } from "../../../helpers/backend_helper";

import { toast } from "react-toastify";
import i18n from "i18n";

function* fetchUnit() {
  try {
    const response = yield call(getUnits);
    yield put(getUnitsSuccess(response.rs.data));
  } catch (err) {
    yield put(getUnitsFail(err));
  }
}

function* postNewUnit(unit) {
  try {
    const response = yield call(postUnit, unit.payload);
    if (response.sts.msg === 'OK') {
      toast.dismiss();
      toast.success(i18n.t("Notes added successfully"));
      yield put(postUnitSuccess(response.rs));
    }
  } catch (err) {
    toast.dismiss();
    toast.error(i18n.t("Something Error"));
    yield put(postUnitFail(err));

  }
}

function* noteSaga() {
  yield takeEvery(GET_UNITS, fetchUnit);
  yield takeEvery(POST_UNIT, postNewUnit);
}

export default noteSaga;