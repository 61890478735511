import axios from "axios";


const axiosAuthInstanceImg = axios.create({
    baseURL: "http://restaurantapifortraining.smartvillageqatar.com/",
    Accept: "application/json",
    "Content-Type": "multipart/form-data"
})

axiosAuthInstanceImg.interceptors.request.use(function (config) {
    config.headers.Authorization =  `Bearer ${localStorage.getItem('token')}`;
    return config;
});

export default axiosAuthInstanceImg;