import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
    GET_TBL_SAFE,
    ADD_SAFE_TRANSFER,
    UPLOAD_SAFE_TRANSFER_IMAGES,
} from "./actionTypes";

import {
    getTblSafeSuccess,
    getTblSafeFail,
    addSafeTransferSuccess,
    addSafeTransferFail,
    uploadSafeTransferImagesSuccess,
    uploadSafeTransferImagesFail,
} from "./actions";

import {
    getSafeList,
    addSafeTransfer,
    uploadSafeTransferImage,
} from "helpers/backend_helper";
import i18n from "i18n";

function* onGetTblSafe() {
    try {
        const response = yield call(getSafeList);
        yield put(getTblSafeSuccess(response.rs.data));
    } catch (error) {
        yield put(getTblSafeFail(error));
    }
};

function* onAddSafeTransfer({ payload: safeTransfer }) {
    try {
        const response = yield call(addSafeTransfer, safeTransfer);
        yield put(addSafeTransferSuccess(response));
    } catch (error) {
        yield put(addSafeTransferFail(error));
    }
};

function* onUploadSafeTransferImages({ payload: data }) {
    try {
        const formData = new FormData();
        formData.append('file', data.file[0]);
        const response = yield call(uploadSafeTransferImage, data.safeTransferId, formData);
        yield put(uploadSafeTransferImagesSuccess(response));
        toast.dismiss();
        toast.success(i18n.t("File uploaded successfully"));
    } catch (error) {
        toast.dismiss();
        toast.error(i18n.t("Problem occured when upload file. Please, try again"));
        yield put(uploadSafeTransferImagesFail(error));
    }
};

function* safeTransferSaga() {
    yield takeEvery(GET_TBL_SAFE, onGetTblSafe);
    yield takeEvery(ADD_SAFE_TRANSFER, onAddSafeTransfer);
    yield takeEvery(UPLOAD_SAFE_TRANSFER_IMAGES, onUploadSafeTransferImages);
};

export default safeTransferSaga;