import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { Row, Col, Label, Spinner } from "reactstrap";
import classes from "./index.module.scss";
import CompaniesModal from "./Modal/CompaniesModal";

import { useDispatch, useSelector } from "react-redux";
import { getTblEmp } from "store/actions";
import { convertDate } from "pages/Reports/helpers";
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf";

const ShiftDetails = (props) => {
    const dispatch = useDispatch();
    const [showCompayModal, setShowCompanyModal] = useState(false);
    const [empName, setEmpName] = useState('');
    const [inputValues, setInputValuse] = useState({
        "Total_Orders": 0,
        "After_Discount": 0,
        "Discount": 0,
        "Total_Cash": 0,
        "Total_Visa": 0,
        "Total_Host": 0,
        "Orders_Count": 0,
        "Returns_Count": 0,
        "Total_Return": 0,
        "Expenses_Count": 0,
        "Total_Expenses": 0,
        "Total_Unpaid_Delivery": 0,
        "Total_Unpaid_Tables": 0,
        "Value_Carried_Over_From": 0,
        "Value_Carried_Over_To": 0,
        "Net_Cash": 0,
        "Net_Orders_Value": 0,
        "Total_Companies": 0,
    });

    const reports = useSelector((state) => state.Reports);
    const endShiftReport = useSelector((state) => state.endShiftReport);
    const { getTblEmp: tblEmp } = reports;
    const { getshiftDetails: shiftDetails, loadingshiftDetails: loading } = endShiftReport;

    // set Emp name
    useEffect(() => {
        if (tblEmp && tblEmp.length === 1) setEmpName(tblEmp[0].empName);
    }, [tblEmp]);

    // Set shift details && get employee name
    useEffect(() => {
        if (shiftDetails && !shiftDetails.Table || !shiftDetails.Table.length) return;
        setInputValuse({
            "Total_Orders": shiftDetails.Table && shiftDetails.Table.length > 0 ? shiftDetails.Table[0].price || 0 : 0,
            "After_Discount": shiftDetails.Table && shiftDetails.Table.length > 0 ? shiftDetails.Table[0].TotalAfterDiscount || 0 : 0,
            "Discount": shiftDetails.Table && shiftDetails.Table.length > 0 ? shiftDetails.Table[0].Discount || 0 : 0,
            "Total_Cash": shiftDetails.Table && shiftDetails.Table.length > 0 ? shiftDetails.Table[0].Cashprice || 0 : 0,
            "Total_Visa": shiftDetails.Table && shiftDetails.Table.length > 0 ? shiftDetails.Table[0].Visaprice || 0 : 0,
            "Total_Host": shiftDetails.Table3 && shiftDetails.Table3.length > 0 ? shiftDetails.Table3[0].ordercount || 0 : 0,
            "Orders_Count": shiftDetails.Table && shiftDetails.Table.length > 0 ? shiftDetails.Table[0].ordercount || 0 : 0,
            "Returns_Count": shiftDetails.Table1 && shiftDetails.Table1.length > 0 ? shiftDetails.Table1[0].returncount || 0 : 0,
            "Total_Return": shiftDetails.Table1 && shiftDetails.Table1.length > 0 ? shiftDetails.Table1[0].returnedvalue || 0 : 0,
            "Expenses_Count": shiftDetails.Table2 && shiftDetails.Table2.length > 0 ? shiftDetails.Table2[0].expensecount || 0 : 0,
            "Total_Expenses": shiftDetails.Table2 && shiftDetails.Table2.length > 0 ? shiftDetails.Table2[0].price || 0 : 0,
            "Total_Unpaid_Delivery": shiftDetails.Table6 && shiftDetails.Table6.length > 0 ? shiftDetails.Table6[0].NotPaidDelivery || 0 : 0,
            "Total_Unpaid_Tables": shiftDetails.Table7 && shiftDetails.Table7.length > 0 ? shiftDetails.Table7[0].NotPaidTables || 0 : 0,
            "Value_Carried_Over_From": shiftDetails.Table8 && shiftDetails.Table8.length > 0 ? shiftDetails.Table8[0].TransferedFrom || 0 : 0,
            "Value_Carried_Over_To": shiftDetails.Table9 && shiftDetails.Table9.length > 0 ? shiftDetails.Table9[0].TransferedTo || 0 : 0,
            "Net_Cash": shiftDetails.Table10 && shiftDetails.Table10.length > 0 ? shiftDetails.Table10[0].NetCash || 0 : 0,
            "Net_Orders_Value": shiftDetails.Table[0].TotalAfterDiscount
                ? shiftDetails.Table[0].TotalAfterDiscount - shiftDetails.Table1[0].returnedvalue || 0
                : 0,
            "Total_Companies": shiftDetails.Table12 && shiftDetails.Table12.length > 0 ? shiftDetails.Table12.length || 0 : 0,
        });

        setEmpName('');
        if (shiftDetails.Table11 && shiftDetails.Table11.length > 0 && shiftDetails.Table11[0].FK_EmpID) {
            dispatch(getTblEmp(`pkEmpId=${shiftDetails.Table11[0].FK_EmpID}`));
        }
    }, [shiftDetails, loading]);

    const toggleCompanyModal = () => {
        setShowCompanyModal(prevState => !prevState);
    };

    return (
        <>
            {loading
                ? <div className="text-center py-5"><Spinner className="ms-2" color="primary" /></div>
                : <>
                    <div className="mb-4" style={{ display: "flex", justifyContent: "center", alignItems: "baseline", columnGap: '10px' }}>
                        <h3 className="h2">{empName ? empName + ',' : ""}</h3>
                        <p className="h5">
                            {shiftDetails && shiftDetails.Table11 && shiftDetails.Table11[0] && shiftDetails.Table11[0].StartTime
                                ? convertDate(new Date(shiftDetails.Table11[0].StartTime))
                                : <></>}
                        </p>
                    </div>
                    <Row>
                        {Object.entries(inputValues).map(([key, value], i) => (
                            <Col key={i} md={3} xs={6}>
                                <div className="mb-3">
                                    <Label className={`${classes.res_font}`}>
                                        {props.t(key.replace(/_/g, " "))}
                                    </Label>
                                    {i < Object.entries(inputValues).length - 1 ?
                                        <div className={`${classes.inputview}`}>{value}</div>
                                        :
                                        <div
                                            className={`${shiftDetails.Table12 && shiftDetails.Table12.length
                                                ? classes.btn_view
                                                : classes.inputview
                                                } position-relative`}
                                            style={{
                                                cursor:
                                                    shiftDetails.Table12 && shiftDetails.Table12.length
                                                        ? "pointer"
                                                        : "unset"
                                            }}
                                            onClick={
                                                shiftDetails.Table12 && shiftDetails.Table12.length
                                                    ? toggleCompanyModal
                                                    : () => { }
                                            }
                                        >
                                            {value}
                                            {shiftDetails.Table12 && shiftDetails.Table12.length
                                                ? <i className={`mdi mdi-eye-outline d-block position-absolute ${classes.icon_absolute}`} />
                                                : <></>
                                            }
                                        </div>
                                    }
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <CompaniesModal
                        companyModal={showCompayModal}
                        handleCompanyToggle={toggleCompanyModal}
                        compList={shiftDetails.Table12 || []}
                    />
                </>
            }
            {!loading && empName ?
                <div className="mt-2 d-flex justify-content-end">
                    <SendAndPrintPdf
                        extraData={{
                            "Employee": empName,
                            "Shift start in": shiftDetails && shiftDetails.Table11 && shiftDetails.Table11[0] && shiftDetails.Table11[0].StartTime ? convertDate(new Date(shiftDetails.Table11[0].StartTime)) : convertDate(new Date()),
                        }}
                        data={Object.entries(inputValues).map(one => ({ [one[0]]: one[1] }))}
                        filteredData={null}
                    />
                </div>
                : <></>
            }
        </>
    );
};

ShiftDetails.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(ShiftDetails));