import {
  GET_ITEM_NOTES,
  GET_ITEM_NOTES_SUCCESS,
  GET_ITEM_NOTES_FAIL,
} from "./actionTypes"

export const getItemNotes = itemNote => ({
  type: GET_ITEM_NOTES,
  itemNote,
})

export const getItemNotesSuccess = itemNotes => ({
  type: GET_ITEM_NOTES_SUCCESS,
  payload: itemNotes,
})

export const getItemNotesFail = itemNotes => ({
  type: GET_ITEM_NOTES_FAIL,
  payload: itemNotes,
})
