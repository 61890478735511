import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import {
    Row,
    Col,
    Form,
    Input,
    Label,
    TabPane,
    FormFeedback,
    CardBody,
} from "reactstrap";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useDispatch } from "react-redux";
import { getShifDetailsByShiftId } from "store/end-shift-report/actions";
import ShiftDetails from "./ShiftDetails";

const FilterByShiftId = (props) => {
    const dispatch = useDispatch();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            shiftId: "",
        },
        validationSchema: Yup.object({
            shiftId: Yup.string().required("Please enter shift Id."),
        }),
        onSubmit: values => {
            // 
        },
    });

    // Fetch shift details
    useEffect(() => {
        if (!validation.values.shiftId) return;
        dispatch(getShifDetailsByShiftId(validation.values.shiftId));
    }, [validation.values.shiftId]);

    return (
        <TabPane tabId="1" id="buy">
            <Row className="mb-3">
                <CardBody>
                    <Form
                        onSubmit={e => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col sm="3">
                                <div className="mb-3">
                                    <Label htmlFor="shiftId">{props.t("Shift Id")}</Label>
                                    <Input
                                        id="shiftId"
                                        name="shiftId"
                                        type="number"
                                        placeholder={props.t("Enter Shift Id")}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.shiftId || ""}
                                        invalid={
                                            validation.touched.shiftId &&
                                                validation.errors.shiftId
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.shiftId &&
                                        validation.errors.shiftId ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.shiftId}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Row>
            <ShiftDetails />
        </TabPane>
    );
};

FilterByShiftId.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(FilterByShiftId));
