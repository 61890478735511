import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_MATERIALS, POST_MATERIAL } from "./actionTypes";

import { getMaterialsSuccess, getMaterialsFail, postMaterialSuccess, postMaterialFail } from "./actions";

import { getMaterials, postMaterial } from "../../../helpers/backend_helper";

import { toast } from "react-toastify";
import i18n from "i18n";


function* fetchMaterial() {
  try {
    const response = yield call(getMaterials);
    yield put(getMaterialsSuccess(response.rs.data));
  } catch (err) {
    yield put(getMaterialsFail(err));
  }
}

function* postNewMaterial(item) {
  try {
    const response = yield call(postMaterial, item.payload);
    if (response.sts.msg === 'OK') {
      toast.dismiss();
      toast.success(i18n.t("Material added successfully"));
      yield put(postMaterialSuccess(response.rs));
    }
  } catch (err) {
    toast.dismiss();
    toast.error(i18n.t("Something Error"));
    yield put(postMaterialFail(err));

  }
}

function* materialSaga() {
  yield takeEvery(GET_MATERIALS, fetchMaterial);
  yield takeEvery(POST_MATERIAL, postNewMaterial);
}

export default materialSaga;