import { call, put, takeEvery } from "redux-saga/effects"

import { SAVE_BUY_MATERIAL } from "./actionTypes"

import { saveBuyMaterialSuccess, saveBuyMaterialFail } from "./actions"

import { saveBuyMaterial } from "helpers/backend_helper"

function* onSaveBuyMaterial({ payload: bodyData }) {
  try {
    const response = yield call(saveBuyMaterial, bodyData)
    yield put(saveBuyMaterialSuccess(response))
  } catch (error) {
    yield put(saveBuyMaterialFail(error))
  }
}

function* purchaseSaga() {
  yield takeEvery(SAVE_BUY_MATERIAL, onSaveBuyMaterial)
}

export default purchaseSaga
