import {
  GET_UNITS,
  GET_UNITS_SUCCESS,
  GET_UNITS_FAIL,
  POST_UNIT,
  POST_UNIT_SUCCESS,
  POST_UNIT_FAIL,
} from "./actionTypes"

export const getUnits = () => ({
    type: GET_UNITS,
  })
  
  export const getUnitsSuccess = units => ({
    type: GET_UNITS_SUCCESS,
    payload: units,
  })
  
  export const getUnitsFail = err => ({
    type: GET_UNITS_FAIL,
    payload: err,
  })

  export const postUnit = (unit) => ({
    type: POST_UNIT,
    payload: unit
  })
  
  export const postUnitSuccess = unit => ({
    type: POST_UNIT_SUCCESS,
    payload: unit,
  })
  
  export const postUnitFail = err => ({
    type: POST_UNIT_FAIL,
    payload: err,
  })
  

