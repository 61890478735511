import React, { useMemo } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import TableContainer from "components/Common/TableContainer";
import { CardBody } from "reactstrap";

import { useSelector } from "react-redux";

const ItemsSalesList = (props) => {
    const reports = useSelector(state => state.Reports);
    const { getItemsSales: getItemsSalesData } = reports;

    const columns = useMemo(
        () => [
            {
                Header: props.t('Name'),
                accessor: 'ItemName',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: props.t('Category'),
                accessor: 'CategoryName',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: props.t('Price'),
                accessor: 'Price',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: props.t('Quantity'),
                accessor: 'totalQty',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: props.t('Total'),
                accessor: 'TotalSales',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
        ],
        [getItemsSalesData, props.t]
    );

    return (
        <CardBody>
            <TableContainer
                columns={columns}
                data={getItemsSalesData}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={5}
                allListSize={getItemsSalesData.length}
            />
        </CardBody>
    );
};

ItemsSalesList.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(ItemsSalesList));
