import {
    GET_SHIF_EMPLOYEES_SUCCESS,
    GET_SHIF_EMPLOYEES_FAIL,
    GET_SHIF_DETAILS,
    GET_SHIF_DETAILS_SUCCESS,
    GET_SHIF_DETAILS_FAIL,
    GET_MANAGER_APPROVAL_SUCCESS,
    GET_MANAGER_APPROVAL_FAIL,
    GET_MANAGER_APPROVAL_RESET,
    SET_END_SHIFT_EMP_ID_SUCCESS,
    SET_END_SHIFT_EMP_ID_FAIL,
    SET_END_SHIFT_EMP_ID_RESET,
    GET_EMAILS_SUCCESS,
    GET_EMAILS_FAIL,
    SEND_EMAIL,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAIL
} from "store/end-shift/actionTypes";

const INIT_STATE = {
    loadingShiftDetails: false,
    shiftDetails: [],
    shiftEmployees: [],
    managerApproval: [],
    emails: [],
    loadingSendEmail: false,
    sendEmail: [],
    endShiftEmpId: [],
    error: {}
};

const shiftDetails = (state = INIT_STATE, action) => {
    switch (action.type) {
        // -- loading
        case GET_SHIF_DETAILS:
            return {
                ...state,
                loadingShiftDetails: true,
                shiftDetails: []
            };

        case SEND_EMAIL:
            return {
                ...state,
                loadingSendEmail: true
            };

        // -- success
        case GET_SHIF_EMPLOYEES_SUCCESS:
            return {
                ...state,
                shiftEmployees: action.payload
            };

        case GET_SHIF_DETAILS_SUCCESS:
            return {
                ...state,
                loadingShiftDetails: false,
                shiftDetails: action.payload
            };

        case GET_MANAGER_APPROVAL_SUCCESS:
            return {
                ...state,
                managerApproval: action.payload
            };

        case GET_MANAGER_APPROVAL_RESET:
            return {
                ...state,
                managerApproval: []
            };

        case SET_END_SHIFT_EMP_ID_SUCCESS:
            return {
                ...state,
                endShiftEmpId: action.payload
            };

        case SET_END_SHIFT_EMP_ID_RESET:
            return {
                ...state,
                endShiftEmpId: []
            };

        case GET_EMAILS_SUCCESS:
            return {
                ...state,
                emails: action.payload
            };

        case SEND_EMAIL_SUCCESS:
            return {
                ...state,
                loadingSendEmail: false,
                sendEmail: action.payload
            };

        // -- fail
        case GET_SHIF_EMPLOYEES_FAIL:
        case GET_MANAGER_APPROVAL_FAIL:
        case SET_END_SHIFT_EMP_ID_FAIL:
        case GET_EMAILS_FAIL:
        case SEND_EMAIL_FAIL:
        case GET_SHIF_DETAILS_FAIL:
            return {
                ...state,
                loadingShiftDetails: false,
                loadingSendEmail: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export default shiftDetails;
