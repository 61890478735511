import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import classnames from "classnames";
import {
    Card,
    CardBody,
    Col,
    Row,
    NavLink,
    NavItem,
    Spinner
} from "reactstrap";
import TransactionModal from "./transaction";
import Breadcrumbs from "components/Common/Breadcrumb";

import { useSelector } from "react-redux";
import FilterByShift from "./FilterByShift";
import FilterByEmployee from "./FilterByEmployee";
import { convertDate } from "pages/Reports/helpers";
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf";

const ShiftReport = (props) => {
    //meta title
    document.title = "Reports | Shift Report";

    const [modal1, setModal1] = useState(false);
    const [activeTab, setActiveTab] = useState("filter_by_shift");
    const [priceAndDiscount, setPriceAndDiscount] = useState({ price: 0, AfterDiscount: 0 });
    const [startEndDate, setStartEndDate] = useState({ startDate: '', endDate: '' });
    const [orderMasterId, setOrderMasterId] = useState("");

    const reports = useSelector(state => state.Reports);
    const { getShiftReport: shiftReportData, loadingShiftReport: loading } = reports;

    useEffect(() => {
        // set price and discount
        if (shiftReportData) {
            const price = shiftReportData.Table1 && shiftReportData.Table1.length > 0 ? shiftReportData.Table1.reduce((acc, curr) => acc + (curr.TotalPrice || 0), 0).toFixed(2) : 0;
            const AfterDiscount = shiftReportData.Table1 && shiftReportData.Table1.length > 0 ? shiftReportData.Table1.reduce((acc, curr) => acc + (curr.totalafterDiscount || 0), 0).toFixed(2) : 0;
            setPriceAndDiscount({ price, AfterDiscount });
        }
        // set start and end date
        if (shiftReportData && shiftReportData.Table && shiftReportData.Table.length) {
            const startDate = new Date(shiftReportData.Table[0].StartTime);
            const endDate = new Date(shiftReportData.Table[0].Endtime);
            setStartEndDate({ startDate: convertDate(startDate), endDate: convertDate(endDate) });
        }
    }, [loading]);

    const closeViewModal = () => setModal1(prevState => !prevState);
    const openViewModal = useCallback((id) => {
        setOrderMasterId(id);
        closeViewModal();
    }, []);

    const toggleTab = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <TransactionModal
                isOpen={modal1}
                orderMasterId={orderMasterId}
                toggle={closeViewModal}
            />
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title={props.t("Shift")}
                        breadcrumbItem={props.t("Shift Reports")}
                    />
                    <Card>
                        <ul className="nav nav-tabs nav-tabs-custom">
                            {[
                                { content: props.t('Filter By Shift'), activeClass: "filter_by_shift" },
                                { content: props.t('Filter by Employee'), activeClass: "filter_by_employee" }
                            ].map((one, i) =>
                                <NavItem
                                    key={i}
                                >
                                    <NavLink
                                        className={classnames({
                                            active: activeTab === one.activeClass,
                                        })}
                                        onClick={() => {
                                            toggleTab(one.activeClass);
                                        }}
                                    >
                                        {one.content}
                                    </NavLink>
                                </NavItem>
                            )}
                        </ul>
                    </Card>
                    <Row>
                        <Col lg="12" xxl="8">
                            {activeTab === 'filter_by_shift'
                                ? <FilterByShift toggleViewModalHandler={openViewModal} />
                                : <FilterByEmployee toggleViewModalHandler={openViewModal} />
                            }
                        </Col>
                        <Col lg="12" xxl="4">
                            <Card className="mt-5">
                                <CardBody>
                                    <div className="table-responsive" style={{ maxWidth: "600px", margin: "0 auto" }}>
                                        <table className="table table-bordered table-striped table-nowrap mb-0">
                                            <tbody>
                                                {shiftReportData && shiftReportData.Table && shiftReportData.Table.length > 0 &&
                                                    <>
                                                        <tr>
                                                            <th className="text-nowrap" scope="row">{props.t("Employee")}</th>
                                                            <td>{shiftReportData && shiftReportData.Table[0] && shiftReportData.Table[0].EmpName ? shiftReportData.Table.length === 1 ? shiftReportData.Table[0].EmpName : props.t("All") : ""}</td>
                                                        </tr>
                                                        <tr>
                                                            <th className="text-nowrap" scope="row">{props.t("Shift No.")}</th>
                                                            <td>{shiftReportData && shiftReportData.Table[0] && shiftReportData.Table[0].PK_Shift_ID ? shiftReportData.Table.length === 1 ? shiftReportData.Table[0].PK_Shift_ID : props.t("All") : ""}</td>
                                                        </tr>
                                                    </>
                                                }
                                                <tr>
                                                    <th className="text-nowrap" scope="row">{props.t("Start")}</th>
                                                    <td>{startEndDate.startDate}</td>
                                                </tr>
                                                <tr>
                                                    <th className="text-nowrap" scope="row">{props.t("End")}</th>
                                                    <td>{startEndDate.endDate}</td>
                                                </tr>
                                                <tr>
                                                    <th className="text-nowrap" scope="row">{props.t("Total Price")}</th>
                                                    <td>{priceAndDiscount.price}</td>
                                                </tr>
                                                <tr>
                                                    <th className="text-nowrap" scope="row">{props.t("After Discount")}</th>
                                                    <td>{priceAndDiscount.AfterDiscount}</td>
                                                </tr>
                                                <tr>
                                                    <th className="text-nowrap" scope="row">{props.t("Transaction Number")}</th>
                                                    <td>{shiftReportData && shiftReportData.Table1 ? shiftReportData.Table1.length : 0}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        {!loading && shiftReportData && shiftReportData.Table1 && shiftReportData.Table1.length ?
                            <div className="mt-2 d-flex justify-content-end">
                                <SendAndPrintPdf
                                    data={shiftReportData}
                                    extraData={{
                                        "Employee": shiftReportData && shiftReportData.Table[0] && shiftReportData.Table[0].EmpName ? shiftReportData.Table.length === 1 ? shiftReportData.Table[0].EmpName : "All" : "",
                                        "Shift_no": shiftReportData && shiftReportData.Table[0] && shiftReportData.Table[0].PK_Shift_ID ? shiftReportData.Table.length === 1 ? shiftReportData.Table[0].PK_Shift_ID : "All" : "",
                                        "Start": startEndDate.startDate,
                                        "End": startEndDate.endDate,
                                        "Total_price": priceAndDiscount.price,
                                        "After_discount": priceAndDiscount.AfterDiscount,
                                        "Transaction_number": shiftReportData && shiftReportData.Table1 ? shiftReportData.Table1.length : 0,
                                        "Items_no": shiftReportData.Table1.length || 0
                                    }}
                                    filteredData={{
                                        Table1: ["No", "OrderType", "TotalPrice", "totalafterDiscount", "waiter_name", "Date"],
                                        Table2: ["No", "ItemName", "Qty", "ItemPrice", "TotalItemPrice", "Notes"]
                                    }}
                                />
                            </div>
                            : <></>
                        }
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

ShiftReport.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(ShiftReport));