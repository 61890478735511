const ADD_EXPENSE = "ADD_EXPENSE";
const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS";
const ADD_EXPENSE_FAIL = "ADD_EXPENSE_FAIL";

const UPLOAD_EXPENSE_IMAGES = "UPLOAD_EXPENSE_IMAGES";
const UPLOAD_EXPENSE_IMAGES_SUCCESS = "UPLOAD_EXPENSE_IMAGES_SUCCESS";
const UPLOAD_EXPENSE_IMAGES_FAIL = "UPLOAD_EXPENSE_IMAGES_FAIL";

export {
    ADD_EXPENSE,
    ADD_EXPENSE_SUCCESS,
    ADD_EXPENSE_FAIL,
    UPLOAD_EXPENSE_IMAGES,
    UPLOAD_EXPENSE_IMAGES_SUCCESS,
    UPLOAD_EXPENSE_IMAGES_FAIL,
};
