import React, { useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { Row } from "reactstrap";
import * as Yup from "yup";

import { useFormik } from "formik";

const DateFromTo = (props) => {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateFrom: "",
      dateTo: new Date().toISOString().split('T')[0],
    },
    validationSchema: Yup.object({
      dateFrom: Yup.string().required("dateFrom is required"),
      dateTo: Yup.string().required("dateTo is required"),
    }),
    onSubmit: values => {
      //
    },
  });

  const { dateFrom, dateTo } = validation.values;
  useEffect(() => {
    props.getDateFromTo({
      dateFrom: dateFrom,
      dateTo: dateTo,
    });
  }, [dateFrom, dateTo]);

  return (
    <Row className="gap-4 mx-1 col-auto">
      <div className="col-md-auto">
        <div className="form-group row px-0">
          <label htmlFor="dateFrom" className="col-auto col-form-label px-0">
            {props.t("Date From")}
          </label>
          <div className="col-auto">
            <input
              className="form-control"
              type="date"
              id="dateFrom"
              name="dateFrom"
              placeholder="Date From"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.dateFrom || ""}
              style={{ width: "200px" }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-auto">
        <div className="form-group row px-0">
          <label htmlFor="dateTo" className="col-auto col-form-label px-0">
            {props.t("Date To")}
          </label>
          <div className="col-auto">
            <input
              className="form-control"
              type="date"
              id="dateTo"
              name="dateTo"
              placeholder="Date To"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.dateTo || ""}
              style={{ width: "200px" }}
            />
          </div>
        </div>
      </div>
    </Row>
  );
};

DateFromTo.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(DateFromTo));
