import {
    GET_PREPARE_AREAS,
    GET_PREPARE_AREAS_SUCCESS,
    GET_PREPARE_AREAS_FAIL,
    POST_PREPARE_AREA,
    POST_PREPARE_AREA_SUCCESS,
    POST_PREPARE_AREA_FAIL
  } from "./actionType"
  
  export const getPrepareArea = () => ({
    type: GET_PREPARE_AREAS,
  })
  
  export const getPrepareAreaSuccess = prepareAreas => ({
    type: GET_PREPARE_AREAS_SUCCESS,
    payload: prepareAreas,
  })
  
  export const getPrepareAreaFail = err => ({
    type: GET_PREPARE_AREAS_FAIL,
    payload: err,
  })
  
  // POST prepare area
  // Post material actions
  export const postPrepareArea = (prepareArea) => ({
    type: POST_PREPARE_AREA,
    payload: prepareArea
  })
  
  export const postPrepareAreaSuccess = prepareArea => ({
    type: POST_PREPARE_AREA_SUCCESS,
    payload: prepareArea,
  })
  
  export const postPrepareAreaFail = err => ({
    type: POST_PREPARE_AREA_FAIL,
    payload: err,
  })
