import {
    SAVE_BUY_MATERIAL,
    SAVE_BUY_MATERIAL_SUCCESS,
    SAVE_BUY_MATERIAL_FAIL,
} from "./actionTypes";


const saveBuyMaterial = (bodyData) => ({
    type: SAVE_BUY_MATERIAL,
    payload: bodyData
});

const saveBuyMaterialSuccess = (res) => ({
    type: SAVE_BUY_MATERIAL_SUCCESS,
    payload: res
});

const saveBuyMaterialFail = (error) => ({
    type: SAVE_BUY_MATERIAL_FAIL,
    payload: error
});



export {
    saveBuyMaterial,
    saveBuyMaterialSuccess,
    saveBuyMaterialFail,
    
};