import {
    ADD_EXPENSE_SUCCESS,
    ADD_EXPENSE_FAIL,
    UPLOAD_EXPENSE_IMAGES_SUCCESS,
    UPLOAD_EXPENSE_IMAGES_FAIL,
} from "store/expense/actionTypes";

const INIT_STATE = {
    expenseId: "",
    uploadExpense: {},
    error: {},
};

const expenses = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_EXPENSE_SUCCESS:
            return {
                ...state,
                expenseId: action.payload,
            };

        case UPLOAD_EXPENSE_IMAGES_SUCCESS:
            return {
                ...state,
                uploadExpense: action.payload,
            };

        case ADD_EXPENSE_FAIL:
        case UPLOAD_EXPENSE_IMAGES_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    };
};

export default expenses;