import {
    GET_TBL_SUPPLIER_PAYMENT,
    GET_TBL_SUPPLIER_PAYMENT_SUCCESS,
    GET_TBL_SUPPLIER_PAYMENT_FAIL,
    GET_TBL_SUPPLIER,
    GET_TBL_SUPPLIER_SUCCESS,
    GET_TBL_SUPPLIER_FAIL,

    ADD_NEW_SUPPLIER,
    ADD_NEW_SUPPLIER_SUCCESS,
    ADD_NEW_SUPPLIER_FAIL
} from "store/supplier/actionTypes";


const tblSupplierPayment = () => ({
    type: GET_TBL_SUPPLIER_PAYMENT
});

const tblSupplierPaymentSuccess = (data) => ({
    type: GET_TBL_SUPPLIER_PAYMENT_SUCCESS,
    payload: data
});

const tblSupplierPaymentFail = (error) => ({
    type: GET_TBL_SUPPLIER_PAYMENT_FAIL,
    payload: error
});

const tblSupplier = () => ({
    type: GET_TBL_SUPPLIER
});

const tblSupplierSuccess = (data) => ({
    type: GET_TBL_SUPPLIER_SUCCESS,
    payload: data
});

const tblSupplierFail = (error) => ({
    type: GET_TBL_SUPPLIER_FAIL,
    payload: error
});

const addNewSupplier = (bodyData) => ({
    type: ADD_NEW_SUPPLIER,
    payload: bodyData
});

const addNewSupplierSuccess = (res) => ({
    type: ADD_NEW_SUPPLIER_SUCCESS,
    payload: res
});

const addNewSupplierFail = (error) => ({
    type: ADD_NEW_SUPPLIER_FAIL,
    payload: error
});


export {
    tblSupplierPayment,
    tblSupplierPaymentSuccess,
    tblSupplierPaymentFail,
    tblSupplier,
    tblSupplierSuccess,
    tblSupplierFail,
    addNewSupplier,
    addNewSupplierSuccess,
    addNewSupplierFail
}

