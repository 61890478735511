import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
    ADD_EXPENSE,
    UPLOAD_EXPENSE_IMAGES,
} from "store/expense/actionTypes";
import {
    addExpenseFail,
    addExpenseSuccess,
    uploadExpenseImageSuccess,
    uploadExpenseImageFail,
} from "store/expense/actions";
import {
    addExpense,
    uploadExpenseImage,
} from "helpers/backend_helper";
import i18n from "i18n";

function* onUploadExpenseImage({ payload: data }) {
    try {
        const formData = new FormData();
        formData.append('file', data.file[0]);
        const response = yield call(uploadExpenseImage, data.Expense_ID || data.expenseId, formData);
        yield put(uploadExpenseImageSuccess(response));
        toast.dismiss();
        toast.success(i18n.t("File uploaded successfully"));
    } catch (error) {
        toast.dismiss();
        toast.error(i18n.t("Problem occured when upload file. Please, try again"));
        yield put(uploadExpenseImageFail(error));
    }
};

function* onAddExpense({ payload: expenseData }) {
    try {
        const file = expenseData.file;
        delete expenseData.file;
        const response = yield call(addExpense, expenseData);
        yield put(addExpenseSuccess(response));
        toast.success(i18n.t("Form data uploaded successfully"));

        // Dispatch action to trigger onUploadExpenseImage
        if (file) {
            yield put({ type: UPLOAD_EXPENSE_IMAGES, payload: { file: file, expenseId: response } });
        }
    } catch (error) {
        toast.error(i18n.t("Problem occured when upload form data. Please, try again"));
        yield put(addExpenseFail(error));
    }
};

function* expensesSaga() {
    yield takeEvery(ADD_EXPENSE, onAddExpense);
    yield takeEvery(UPLOAD_EXPENSE_IMAGES, onUploadExpenseImage);
}

export default expensesSaga;