import React, { useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
} from "reactstrap";
import { useFormik } from "formik";

import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addNewSupplier } from "store/actions";

const SupplierModal = (props) => {
  const { supplierModal, handleSupplierToggle } = props;
  const dispatch = useDispatch();
  const [supplierName, setSupplierName] = useState("");
  const [supplierBalance, setSupplierBalance] = useState("");
  const [supplierAddress, setSupplierAddress] = useState("");
  const [supplierTel, setSupplierTel] = useState("");

  const toggle = (props) => {
    handleSupplierToggle();
  };


  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      supplierName: supplierName || "",
      supplierBalance: supplierBalance || "",
      supplierAddress: supplierAddress || "",
      supplierTel: supplierTel || "",
    },
    validationSchema: Yup.object({
      supplierName: Yup.string().required(props.t("Please Enter Supplier Name")),
      supplierBalance: Yup.number().required(props.t("Please enter balance")),
    }),
    onSubmit: values => {
      const newSupplier = {
        supplierName: values["supplierName"],
        supplierBalance: values["supplierBalance"],
        supplierAddress: values["supplierAddress"],
        supplierTel: values["supplierTel"],
      };
      dispatch(addNewSupplier(newSupplier));
      toggle();
    },
  });

  return (
    <Modal isOpen={supplierModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Add Supplier")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Supplier Name")}</Label>
                <Input
                  name="supplierName"
                  type="text"
                  value={validation.values.supplierName || ""}
                  invalid={
                    validation.touched.supplierName &&
                      validation.errors.supplierName
                      ? true
                      : false
                  }
                  placeholder={props.t("Enter Supplier Name")}
                  onChange={(e) => setSupplierName(e.target.value)}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.supplierName &&
                  validation.errors.supplierName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.supplierName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Supplier Balance")}</Label>
                <Input
                  name="supplierBalance"
                  type="number"
                  min={0}
                  value={validation.values.supplierBalance || ""}
                  invalid={
                    validation.touched.supplierBalance &&
                      validation.errors.supplierBalance
                      ? true
                      : false
                  }
                  placeholder={props.t("Enter Supplier Balance")}
                  onChange={(e) => setSupplierBalance(e.target.value)}
                  onBlur={validation.handleBlur}
                />
                {validation.touched.supplierBalance &&
                  validation.errors.supplierBalance ? (
                  <FormFeedback type="invalid">
                    {validation.errors.supplierBalance}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Supplier Address")}</Label>
                <Input
                  name="supplierAddress"
                  type="text"
                  value={supplierAddress}
                  placeholder={props.t("Enter Supplier Address")}
                  onChange={e => setSupplierAddress(e.target.value)}
                />
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Supplier Phone")}</Label>
                <Input
                  name="supplierTel"
                  type="text"
                  value={supplierTel}
                  placeholder={props.t("Enter Supplier Phone")}
                  onChange={e => setSupplierTel(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <div className="text-end">
            <button type="submit" className="btn btn-success save-user">
              <i className="mdi mdi-check-circle-outline me-1" />
              {props.t("Save")}
            </button>
            <Button
              type="button"
              color="secondary"
              className="mx-1"
              onClick={toggle}
            >
              <i className="mdi mdi-cancel me-1" />
              {props.t("Cancel")}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

SupplierModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SupplierModal));
