import {
    GET_SHIF_IN_PERIOD,
    GET_SHIF_IN_PERIOD_SUCCESS,
    GET_SHIF_IN_PERIOD_FAIL,
    GET_SHIF_DETAILS_BY_SHIFT_ID,
    GET_SHIF_DETAILS_BY_SHIFT_ID_SUCCESS,
    GET_SHIF_DETAILS_BY_SHIFT_ID_FAIL
} from "./actionTypes";

const INIT_STATE = {
    loadingShiftInPreiod: false,
    getShiftInPreiod: [],
    loadingshiftDetails: false,
    getshiftDetails: {},
    error: {},
};

const endShiftReport = (state = INIT_STATE, action) => {
    switch (action.type) {
        // -- loading
        case GET_SHIF_IN_PERIOD:
            return {
                ...state,
                loadingShiftInPreiod: true,
                getShiftInPreiod: []
            };

        case GET_SHIF_DETAILS_BY_SHIFT_ID:
            return {
                ...state,
                loadingshiftDetails: true,
                getshiftDetails: {}
            };

        // -- success
        case GET_SHIF_IN_PERIOD_SUCCESS:
            return {
                ...state,
                loadingShiftInPreiod: false,
                getShiftInPreiod: action.payload
            };

        case GET_SHIF_DETAILS_BY_SHIFT_ID_SUCCESS:
            return {
                ...state,
                loadingshiftDetails: false,
                getshiftDetails: action.payload
            };

        // -- fail
        case GET_SHIF_IN_PERIOD_FAIL:
        case GET_SHIF_DETAILS_BY_SHIFT_ID_FAIL:
            return {
                ...state,
                loadingShiftInPreiod: false,
                loadingshiftDetails: false,
                error: action.payload
            };

        default:
            return state;
    }
};

export default endShiftReport;