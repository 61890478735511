import { jsPDF } from "jspdf";
// import logo from "../../../../../assets/images/buffalo-logo.png";

const preparePdfHeader = (fileName, extraData, logo) => {
    const doc = new jsPDF();

    // Add SVG as an image before the header
    const svg = {
        img: logo,
        format: "SVG",
        x: 10,
        y: 5,
        width: 12,
        height: 12
    };

    // Add the SVG image before the header
    doc.addImage(svg.img, svg.format, svg.x, svg.y, svg.width, svg.height);

    function capitalizeHeaderWord(str) {
        return str.replace(/\b\w/g, function (char) {
            return char.toUpperCase();
        });
    }

    // Add a header to the PDF
    doc.setFontSize(18);
    doc.setTextColor(251, 148, 14);
    doc.setFont("helvetica", "bold");
    doc.text(capitalizeHeaderWord(fileName), 25, 15);

    // reset font
    doc.setFontSize(10);
    doc.setTextColor(251, 148, 14);

    let startX = 150;
    let startY = 25;
    let margin = 6;

    // exta details
    if (extraData) {
        Object.entries(extraData).forEach(one => {
            doc.text(`${one[0].replace(/_/g, " ")}: ${one[1]}`, startX, startY);
            startY += margin; // Increase Y coordinate for the next text with a margin
        });
    }

    // reset font
    doc.setFont("helvetica", "normal"); // Set font to bold
    doc.setTextColor(0, 0, 0);

    return { doc, margin: 5 + startY };
};

export {
    preparePdfHeader
};;
