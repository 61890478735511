// import {
//     LOGIN_USER,
//     LOGIN_SUCCESS,
//     LOGOUT_USER,
//     LOGOUT_USER_SUCCESS,
//     API_ERROR,
//   } from "./actionTypes"

import {
  LOGIN_USER_DATA,
  LOGIN_SUCCESS_DATA,
  LOGIN_DATA_RESET,
  LOGOUT_USER_CLEAR,
  LOGOUT_USER_SUCCESS_CLEAR,
  LOGIN_ERROR,
} from "./actionTypes";

//   const initialState = {
//     error: "",
//     loading: false,
//   }

const initialState = {
  error: "",
  loading: false,
  userData: localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')) : null
};

const loginUser = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS_DATA:
      state = {
        ...state,
        loading: false,
        userData: action.payload,
        isUserLogout: false,
      };
      break;
    case LOGIN_DATA_RESET:
      state = {
        ...state,
        userData: null,
      };
      break;
    case LOGOUT_USER_CLEAR:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS_CLEAR:
      state = { ...state, isUserLogout: true };
      break;
    case LOGIN_ERROR:
      state = { ...state, error: action.payload, loading: false, isUserLogout: false, };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default loginUser;
