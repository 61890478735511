import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import BarChart from "./BarChart";

import { useSelector } from "react-redux";

const ItemsSalesCharts = (props) => {

    const [quantityChart, setQuantityChart] = useState({ data: [], categories: [] });
    const [priceChart, setPriceChart] = useState({ data: [], categories: [] });

    const reports = useSelector(state => state.Reports);
    const { getItemsSales: getItemsSalesData } = reports;

    useEffect(() => {
        if (!getItemsSalesData.length) return;

        // Sorting by Quantity
        const sortedByQuantity = [...getItemsSalesData].sort((a, b) => b.totalQty - a.totalQty);
        const topTenByQuantity = sortedByQuantity.slice(0, 10);
        setQuantityChart({
            data: topTenByQuantity.map((item) => item.totalQty),
            categories: topTenByQuantity.map((item) => item.ItemName),
        });

        // Sorting by Price
        const sortedByPrice = [...getItemsSalesData].sort((a, b) => b.TotalSales - a.TotalSales);
        const topTenByPrice = sortedByPrice.slice(0, 10);
        setPriceChart({
            data: topTenByPrice.map((item) => item.TotalSales),
            categories: topTenByPrice.map((item) => item.ItemName),
        });
    }, [getItemsSalesData]);

    return (
        <Row>
            <Col lg={6}>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">
                            {props.t("Products Total Sales Chart")}
                        </CardTitle>
                        <BarChart data={priceChart.data} categories={priceChart.categories} />
                    </CardBody>
                </Card>
            </Col>
            <Col lg={6}>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">
                            {props.t("Products Quantity Chart")}
                        </CardTitle>
                        <BarChart data={quantityChart.data} categories={quantityChart.categories} />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

ItemsSalesCharts.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(ItemsSalesCharts));

