import {
  GET_ITEMS,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAIL,
  POST_ITEM,
  POST_ITEM_SUCCESS,
  POST_ITEM_FAIL,
  POST_ITEM_IMG,
  POST_ITEM_IMG_SUCCESS,
  POST_ITEM_IMG_FAIL,
  POST_ITEM_NOTE,
  POST_ITEM_NOTE_SUCCESS,
  POST_ITEM_NOTE_FAIL
} from "./actionTypes"

export const getItems = () => ({
  type: GET_ITEMS,
})

export const getItemsSuccess = items => ({
  type: GET_ITEMS_SUCCESS,
  payload: items,
})

export const getItemsFail = items => ({
  type: GET_ITEMS_FAIL,
  payload: items,
})


// Post Item actions
// export const postItem = (item, selectedFiles, itemNotes) => ({
//   type: POST_ITEM,
//   payload: {item, selectedFiles, itemNotes},
// })

export const postItem = (item, selectedFiles) => ({
  type: POST_ITEM,
  payload: {item, selectedFiles},
})

export const postItemSuccess = item => ({
  type: POST_ITEM_SUCCESS,
  payload: item,
})

export const postItemFail = err => ({
  type: POST_ITEM_FAIL,
  payload: err,
})

// post Item Image
export const postItemImg = (itemId, itemImage) => ({
  type: POST_ITEM_IMG,
  payload: {itemId, itemImage}
})

export const postItemImgSuccess = item => ({
  type: POST_ITEM_IMG_SUCCESS,
  payload: item,
})

export const postItemImgFail = err => ({
  type: POST_ITEM_IMG_FAIL,
  payload: err,
})

// Post Item Note
export const postItemNote = (itemNote) => ({
  type: POST_ITEM_NOTE,
  payload: {itemNote}
})

export const postItemNoteSuccess = (item) => ({
  type: POST_ITEM_NOTE_SUCCESS,
  payload: item
})

export const postItemNoteFail = err => ({
  type: POST_ITEM_NOTE_FAIL,
  payload: err
})