import {
    GET_ITEM_NOTES_SUCCESS,
    GET_ITEM_NOTES_FAIL
  } from "./actionTypes"
  
  const INIT_STATE = {
    itemNotes: [],
    // userProfile: {},
    // error: {},
  }
  
  const itemNotes = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_ITEM_NOTES_SUCCESS:
        return {
          ...state,
          itemNotes: action.payload,
        }
  
      case GET_ITEM_NOTES_FAIL:
        return {
          ...state,
          error: action.payload,
        }
      default:
        return state
    }
  }
  
  export default itemNotes
  