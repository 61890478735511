import {
    SAVE_BUY_MATERIAL_SUCCESS,
    SAVE_BUY_MATERIAL_FAIL,
    
} from "./actionTypes";

const INIT_STATE = {
    saveBuyMaterial: {},
    error: {},
};

const purchase = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SAVE_BUY_MATERIAL_SUCCESS:
            return {
                ...state,
                saveBuyMaterial: action.payload
            };

       
        case SAVE_BUY_MATERIAL_FAIL:
            return {
                ...state,
                error: action.payload
            };

        default:
            return state;
    };
};

export default purchase;