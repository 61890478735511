import {
  LOGIN_USER_DATA,
  LOGIN_SUCCESS_DATA,
  LOGIN_DATA_RESET,
  LOGOUT_USER_CLEAR,
  LOGOUT_USER_SUCCESS_CLEAR,
  LOGIN_ERROR,
} from "./actionTypes";

export const loginUserData = (user, history) => {
  return {
    type: LOGIN_USER_DATA,
    payload: { user, history },
  };
};

export const loginSuccessData = user => {
  return {
    type: LOGIN_SUCCESS_DATA,
    payload: user,
  };
};

export const loginDataReset = () => {
  return {
    type: LOGIN_DATA_RESET,
  };
};

export const logoutUserClear = history => {
  return {
    type: LOGOUT_USER_CLEAR,
    payload: { history },
  };
};

export const logoutUserSuccessClear = () => {
  return {
    type: LOGOUT_USER_SUCCESS_CLEAR,
    payload: {},
  };
};

export const loginError = error => {
  console.log("error", error);
  return {
    type: LOGIN_ERROR,
    payload: error,
  };
};
