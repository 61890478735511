import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from "reactstrap";

import { useSelector } from "react-redux";

const TransactionModal = props => {
    const { isOpen, toggle, orderMasterId } = props;
    const [data, setData] = useState([]);

    const reports = useSelector(state => state.Reports);
    const { getShiftReport: shiftReportData } = reports;

    useEffect(() => {
        if (shiftReportData && shiftReportData.Table2 && orderMasterId) {
            const filterdData = shiftReportData.Table2.filter(item => item.MasterID === orderMasterId);
            const mapedData = filterdData.map(one => {
                return {
                    item: one.ItemName,
                    qty: one.Qty,
                    price: one.ItemPrice,
                    totalPrice: one.Qty * one.ItemPrice,
                    Notes: one.Notes
                };
            });
            setData(mapedData);
        } else {
            setData([]);
        }
    }, [shiftReportData, orderMasterId]);

    return (
        <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
        >
            <div className="modal-content">
                <ModalHeader toggle={toggle}>{props.t("Transaction")}</ModalHeader>
                <ModalBody>
                    <div className="table-responsive">
                        <Table className="table align-middle table-nowrap">
                            <thead>
                                <tr>
                                    <th scope="col">{props.t("Item")}</th>
                                    <th scope="col">{props.t("Qty.")}</th>
                                    <th scope="col">{props.t("Price")}</th>
                                    <th scope="col">{props.t("Total Price")}</th>
                                    <th scope="col">{props.t("Notes")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length > 0 && data.map((one, i) => (
                                    <tr key={i}>
                                        <td>{one.item}</td>
                                        <td>{one.qty}</td>
                                        <td>{one.price}</td>
                                        <td>{one.totalPrice}</td>
                                        <td>{one.Notes}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="success" onClick={toggle}>
                        <i className="bx bx-check-circle label-icon"></i> {props.t("Save")}
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    );
};

TransactionModal.propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(TransactionModal));
