import {
  GET_COMPONENTS_SUCCESS,
  GET_COMPONENTS_FAIL,
  POST_COMPONENT_SUCCESS,
  POST_COMPONENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  allComponents: [],
  // userProfile: {},
  newComponentAdded: 0,
  // error: {},
}

const components = (state = INIT_STATE, action) => {
  
  switch (action.type) {
    case GET_COMPONENTS_SUCCESS:
      return {
        ...state,
        allComponents: action.payload,
      }

    case GET_COMPONENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_COMPONENT_SUCCESS:
      return {
        allComponents: [...state.allComponents],
        // allComponents: [...state.allComponents, action.payload],
        newComponentAdded: action.payload,
      }

    case POST_COMPONENT_FAIL:
      return {
        ...state,
        error: { ...state, error: action.payload },
      }

    default:
      return state
  }
}

export default components
