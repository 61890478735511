import autoTable from "jspdf-autotable";

const preparePdfTwoColumnsTabel = (doc, data, margin) => {
    const bodyData = [];
    // Add a Tabel to the PDF
    data.forEach((obj, i) => {
        bodyData[i] = [Object.keys(obj).toString().replace(/_/g, " "), ...Object.values(obj)];
    });

    autoTable(doc, {
        body: bodyData,
        startY: margin,
        theme: "grid",
        didParseCell: function (data) {
            if (data.row.index % 2 === 0) {
                data.cell.styles.fillColor = [255, 216, 168]; // background color
            }
            data.cell.styles.fontStyle = 'bold'; // font style
        },
    });

    return doc;
};

export {
    preparePdfTwoColumnsTabel
};
