import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import {
    Row,
    TabPane,
    Button,
    UncontrolledTooltip,
    CardBody,
    Spinner
} from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DateFromTo from "pages/Reports/shared/DateFromTo";

import { useDispatch, useSelector } from "react-redux";
import { getShifInPeriod, getShifDetailsByShiftId } from "store/end-shift-report/actions";
import { convertParametersToString } from "pages/Reports/helpers";
import TableContainer from "components/Common/TableContainer";
import ShiftDetails from "pages/EndShiftReport/ShiftDetails";

const FilterByDate = (props) => {
    const dispatch = useDispatch();

    const navigation = useNavigate();
    const { search } = useLocation();

    const query = new URLSearchParams(search);
    const id = query.get("id");

    const endShiftReport = useSelector(state => state.endShiftReport);
    const { getShiftInPreiod: ShiftInPreiod, loadingShiftInPreiod: loading } = endShiftReport;

    // Fetch shift details by shift id in query
    useEffect(() => {
        if (!id) return;
        dispatch(getShifDetailsByShiftId(id));
    }, [id]);

    const getDateFromToHandler = (date) => {
        const parameters = convertParametersToString(date);
        dispatch(getShifInPeriod(parameters));
    };

    const columns = useMemo(
        () => [
            {
                Header: props.t('ID'),
                accessor: 'PK_Shift_ID',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: props.t('Start Shift'),
                accessor: 'StartTime',
                filterable: true,
                Cell: (cellProps) => {
                    const date = new Date(cellProps.value);
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const day = date.getDate();
                    return <>{cellProps.value ? day + "/" + month + "/" + year : ""}</>;
                }
            },
            {
                Header: props.t('End Shift'),
                accessor: 'Endtime',
                filterable: true,
                Cell: (cellProps) => {
                    const date = new Date(cellProps.value);
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const day = date.getDate();
                    return <>{cellProps.value ? day + "/" + month + "/" + year : ""}</>;
                }
            },
            {
                Header: props.t("View"),
                Cell: (cellProps) => {
                    const { PK_Shift_ID } = cellProps.row.original;
                    return (
                        <div className="list-unstyled hstack gap-1 mb-0" >
                            <li>
                                <Button
                                    type="button"
                                    className="btn btn-sm btn-soft-primary"
                                    onClick={() => navigation(`/end-shift-report?id=${PK_Shift_ID}`)}>
                                    <i className="mdi mdi-eye-outline" id="viewtooltip" />
                                    <UncontrolledTooltip placement="top" target="viewtooltip">
                                        {props.t("View")}
                                    </UncontrolledTooltip>
                                </Button>
                            </li>
                        </div >
                    );
                },
            },
        ],
        [ShiftInPreiod, props.t]
    );

    return (
        <TabPane tabId="2">
            <Row>
                <CardBody>
                    <DateFromTo getDateFromTo={getDateFromToHandler} />
                </CardBody>
                <div className="mt-4 mx-auto">
                    {id && (
                        <Link
                            to='/end-shift-report'
                        >
                            <Button
                                type="button"
                                color="primary"
                                className="btn btn-primary btn-label mx-4"
                            >
                                <i className="bx bx-left-arrow label-icon align-middle fs-16 me-2" />
                                {props.t("Back")}
                            </Button>
                        </Link>
                    )}
                    <CardBody>
                        {loading
                            ? <div className="text-center py-5"><Spinner className="ms-2" color="primary" /></div>
                            : <>
                                {id
                                    ? < ShiftDetails />
                                    : <TableContainer
                                        columns={columns}
                                        data={ShiftInPreiod && ShiftInPreiod.Table ? ShiftInPreiod.Table : []}
                                        isGlobalFilter={false}
                                        customPageSize={5}
                                        allListSize={ShiftInPreiod && ShiftInPreiod.Table ? ShiftInPreiod.Table.length : 0}
                                    />
                                }
                            </>
                        }
                    </CardBody>
                </div>
            </Row>
        </TabPane>
    );
};

FilterByDate.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(FilterByDate));

