import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_ITEMS, POST_ITEM, POST_ITEM_IMG, POST_ITEM_NOTE } from "./actionTypes";

import { getItemsSuccess, getItemsFail, postItemSuccess, postItemFail, postItemImgSuccess, postItemImgFail, postItemImg, postItemNoteFail } from "./actions";

import { getItems, postItem, postItemImage, postItemNotes } from "../../../helpers/backend_helper";

import { toast } from "react-toastify";
import i18n from "i18n";


function* fetchItems() {
  try {
    const response = yield call(getItems);
    yield put(getItemsSuccess(response.rs.data));
  } catch (err) {
    yield put(getItemsFail(err));
  }
}

function* postNewItem(item) {
  try {
    const response = yield call(postItem, item.payload.item);
    if (response > 0) {
      if (item.payload.item.item.pkItemId > 0) {
        toast.dismiss();
        toast.success(i18n.t("Item updated successfully"));
      } else {
        toast.dismiss();
        toast.success(i18n.t("Item added successfully"));
      }
      // check if user upload img call img API
      if (item.payload.selectedFiles.get("file")) {
        yield* postNewImg(response, item.payload.selectedFiles);
      }
      // yield* postNewItemNotes(item, response)
      yield put(postItemSuccess(response.rs));

      yield* fetchItems();
    }
  } catch (err) {
    toast.dismiss();
    toast.error(i18n.t("Something Error"));
    yield put(postItemFail(err));

  }
}

function* postNewImg(itemId, img) {
  try {
    const response = yield call(postItemImage, { itemId, img });
    yield put(postItemImgSuccess(response.rs));
  } catch (err) {
    yield put(postItemImgFail(err));
  }
}

// function* postNewItemNotes(item, response) {

//   // check if user add notes add response id to item and notes
//   if( item.payload.itemNotes.itemNotes.length > 0) {
//     let itemNotesList = [...item.payload.itemNotes.itemNotes]
//     itemNotesList.map(note => { note.fkItemId = response
//       delete note.id
//       return note
//     })

//     let itemData = {...item.payload.itemNotes.item, pkItemId: response}
//     let itemNotes = {item: itemData, itemNotes: itemNotesList}
//     try {
//       const response = yield call(postItemNotes, itemNotes)
//     } catch (err) {
//       yield put(postItemNoteFail(err))
//     }
//   } 

// }

function* itemsSaga() {
  yield takeEvery(GET_ITEMS, fetchItems);
  yield takeEvery(POST_ITEM, postNewItem);
  yield takeEvery(POST_ITEM_IMG, postNewImg);
  // yield takeEvery(POST_ITEM_NOTE, postNewItemNotes)
}

export default itemsSaga;
