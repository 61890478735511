import {
    GET_SHIF_IN_PERIOD,
    GET_SHIF_IN_PERIOD_SUCCESS,
    GET_SHIF_IN_PERIOD_FAIL,
    GET_SHIF_DETAILS_BY_SHIFT_ID,
    GET_SHIF_DETAILS_BY_SHIFT_ID_SUCCESS,
    GET_SHIF_DETAILS_BY_SHIFT_ID_FAIL
} from "./actionTypes";


const getShifInPeriod = (parameters) => ({
    type: GET_SHIF_IN_PERIOD,
    payload: parameters,
});

const getShifInPeriodSuccess = data => ({
    type: GET_SHIF_IN_PERIOD_SUCCESS,
    payload: data
});

const getShifInPeriodFail = error => ({
    type: GET_SHIF_IN_PERIOD_FAIL,
    payload: error
});

const getShifDetailsByShiftId = FK_Shift_ID => ({
    type: GET_SHIF_DETAILS_BY_SHIFT_ID,
    payload: FK_Shift_ID
});

const getShifDetailsByShiftIdSuccess = data => ({
    type: GET_SHIF_DETAILS_BY_SHIFT_ID_SUCCESS,
    payload: data
});

const getShifDetailsByShiftIdFail = error => ({
    type: GET_SHIF_DETAILS_BY_SHIFT_ID_FAIL,
    payload: error
});

export {
    getShifInPeriod,
    getShifInPeriodSuccess,
    getShifInPeriodFail,
    getShifDetailsByShiftId,
    getShifDetailsByShiftIdSuccess,
    getShifDetailsByShiftIdFail
};