import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_CATEGORIES, POST_CATEGORY } from "./actionTypes";

import {
  getCategoriesSuccess,
  getCategoriesFail,
  postCategorySuccess,
  postCategoryFail,
} from "./actions";
import { toast } from "react-toastify";
import i18n from "i18n";

import { getCategories, postCategory } from "../../../helpers/backend_helper";


function* fetchCategories() {
  try {
    const response = yield call(getCategories);
    yield put(getCategoriesSuccess(response.rs.data));
  } catch (err) {
    yield put(getCategoriesFail(err));
  }
}

function* postNewCategory(cat) {
  try {
    const response = yield call(postCategory, cat.payload);
    if (response.sts.msg === "OK") {
      toast.dismiss();
      toast.success(i18n.t("Category added successfully"));
      yield put(postCategorySuccess(response.rs));
    }
  } catch (err) {
    toast.dismiss();
    toast.error(i18n.t("Something Error"));
    yield put(postCategoryFail(err));
  }
}

function* categoriesSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories);
  yield takeEvery(POST_CATEGORY, postNewCategory);
}

export default categoriesSaga;
