import {
    GET_MATERIALS,
    GET_MATERIALS_SUCCESS,
    GET_MATERIALS_FAIL,
    POST_MATERIAL,
    POST_MATERIAL_SUCCESS,
    POST_MATERIAL_FAIL
  } from "./actionTypes"
  
  // Get material actions
  export const getMaterials = () => ({
    type: GET_MATERIALS,
  })
  
  export const getMaterialsSuccess = material => ({
    type: GET_MATERIALS_SUCCESS,
    payload: material,
  })
  
  export const getMaterialsFail = err => ({
    type: GET_MATERIALS_FAIL,
    payload: err,
  })

  // Post material actions
  export const postMaterial = (material) => ({
    type: POST_MATERIAL,
    payload: material
  })
  
  export const postMaterialSuccess = material => ({
    type: POST_MATERIAL_SUCCESS,
    payload: material,
  })
  
  export const postMaterialFail = err => ({
    type: POST_MATERIAL_FAIL,
    payload: err,
  })

  