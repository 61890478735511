import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import {
    Col,
    Row,
    Card,
    CardBody,
    Spinner
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

import DailyDate from "pages/Reports/shared/DailyDate";
import { useSelector, useDispatch } from "react-redux";
import { getMonthTrans } from "store/actions";
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf";
const MonthlyReport = (props) => {
    document.title = "Monthly Report | Reports";
    const dispatch = useDispatch();

    const [netCash, setNetCash] = useState(0);
    const [date, setDate] = useState(null);

    const reports = useSelector(state => state.Reports);
    const { getMonthTrans: getMonthTransData, loadingMonthTrans: loading } = reports;

    useEffect(() => {
        if (!getMonthTransData || getMonthTransData.length === 0) return;
        const monArray = getMonthTransData.filter(one => one.description === "Sales" || one.description === "Spendings" || one.description === "Purchase invoices" || one.description === "Withdrawals").map(one => one.mon);
        const netCashValue = monArray.reduce((mon, currentValue) => {
            if (mon || mon === 0) {
                return mon + currentValue;
            }
            return currentValue;
        }, 0);
        setNetCash(netCashValue);
    }, [getMonthTransData]);

    const getDataFilteredByDateHandler = (paramsInString, date) => {
        setDate(date);
        dispatch(getMonthTrans(paramsInString));
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title={props.t("Shift")}
                        breadcrumbItem={props.t("Monthly Report")}
                    />
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody className="border-bottom ml-2">
                                    <Row>
                                        <DailyDate getDataFilteredByDate={getDataFilteredByDateHandler} />
                                    </Row>
                                </CardBody>
                                {getMonthTransData && getMonthTransData.length > 0 &&
                                    <>
                                        {loading
                                            ? <div className="text-center py-5"><Spinner className="ms-2" color="primary" /></div>
                                            : <>
                                                <CardBody className="border-bottom text-center">
                                                    <h6>
                                                        {props.t("Net cash main safe")}
                                                    </h6>
                                                    <h4 className="mb-3">{netCash}</h4>
                                                </CardBody>
                                                <CardBody>
                                                    <div className="table-responsive" style={{ maxWidth: "600px", margin: "0 auto" }}>
                                                        <table className="table table-bordered table-striped table-nowrap mb-0">
                                                            <tbody>
                                                                {getMonthTransData.map((item, index) =>
                                                                    <tr key={index}>
                                                                        <th className="text-nowrap" scope="row">{props.t(item.description)}</th>
                                                                        <td>{index === 0 && item.mon < 0 ? item.mon * -1 : item.mon || 0}</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </CardBody>
                                            </>
                                        }
                                        {!loading && date ?
                                            <div className="mt-2 d-flex justify-content-end">
                                                <SendAndPrintPdf
                                                    data={getMonthTransData.map((item, index) =>
                                                        ({ [item.description]: index === 0 && item.mon < 0 ? item.mon * -1 : item.mon || 0 })
                                                    )}
                                                    extraData={{
                                                        "Date": date,
                                                        "Net_cash_main_safe": netCash
                                                    }}
                                                    filteredData={null}
                                                />
                                            </div>
                                            : <></>
                                        }
                                    </>}
                            </Card>
                        </Col>

                    </Row>
                </div>
            </div>
        </React.Fragment >
    );
};

MonthlyReport.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(MonthlyReport));
