import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
} from "reactstrap";
import classes from "../index.module.scss";
import { useFormik } from "formik";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { postCategory } from "store/actions";


import * as Yup from "yup";
import useSelectInputErrMsg from "pages/Products/hooks/use-selectInputErrMsg";
import SelectInputErrorMessage from "pages/Products/SelectInputErrorMessage";

const SubCategoryModal = (props) => {
  const {
    subCategoryModal,
    handleSubCategoryToggle,
    mainCatElement,
  } = props;
  const dispatch = useDispatch();
  const { errorMsgs, sets, states } = useSelectInputErrMsg({ t: props.t });

  const [subCatName, setSubCatName] = useState("");
  const [selectedMainCat, setSelectedMainCat] = useState(null);
  const [resetMainCat, setResetMainCat] = useState(false);

  const toggle = () => {
    handleSubCategoryToggle();
  };

  // Reset Main Category when model opened
  useEffect(() => {
    setSelectedMainCat("");
  }, []);

  function handleSelectMainCat(selectedMainCat) {
    setSelectedMainCat(Number(selectedMainCat.pkCategory));
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      selectedMainCat: selectedMainCat || "",
      subCatName: subCatName || "",
    },
    validationSchema: Yup.object({
      selectedMainCat: Yup.number().required(props.t("Please Enter Main Category")),
      subCatName: Yup.string().required(props.t("Please Enter Sub-Category")),
    }),
    onSubmit: values => {
      const newSubCat = {
        fkCategoryId: values["selectedMainCat"],
        categoryName: values["subCatName"],
      };
      try {
        dispatch(postCategory(newSubCat));
        validation.resetForm();

        // reset select input
        sets.setSubCategoryModelShowMainCategoryErrMsg(false);
        toggle();
      } catch (err) {
        console.log(err);
      }
    },
  });

  // reset select states in first render
  useEffect(() => {
    sets.setSubCategoryModelShowMainCategoryErrMsg(false);
  }, []);


  const resetSubCatForm = () => {
    setSelectedMainCat("");
    validation.resetForm();
    setSubCatName("");
  };

  const handleCancel = () => {
    resetSubCatForm();
    toggle();
  };

  return (
    <Modal isOpen={subCategoryModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Add Sub-Category")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            sets.setSubCategoryModelShowMainCategoryErrMsg(true);
            return false;
          }}
        >
          <Row>
            <Col sm={12}>
              <div className="mb-3">
                <Label>{props.t("Main Category")}</Label>
                <div className="input-group">
                  <Select
                    value={
                      !resetMainCat
                        ? mainCatElement.find(function (option) {
                          return selectedMainCat
                            ? option.pkCategory ===
                            Number(selectedMainCat.pkCategory)
                            : Number(validation.values.mainCategoryId);
                        })
                        : ""
                    }
                    onChange={e => {
                      handleSelectMainCat(e);
                    }}
                    options={mainCatElement}
                    className={`select2-selection form-control 
                    ${states.subCategoryModelShowMainCategoryErrMsg && !selectedMainCat && classes.red_border} 
                    ${classes.input_with_btn}`}
                    getOptionLabel={mainCatElement =>
                      mainCatElement["categoryName"]
                    }
                    getOptionValue={mainCatElement =>
                      mainCatElement["pkCategory"]
                    }
                    onFocus={() => sets.setSubCategoryModelShowMainCategoryErrMsg(false)}
                    onBlur={() => sets.setSubCategoryModelShowMainCategoryErrMsg(true)}
                  />
                </div>
                {states.subCategoryModelShowMainCategoryErrMsg && !selectedMainCat &&
                  <SelectInputErrorMessage>
                    {errorMsgs.mainCategory}
                  </SelectInputErrorMessage>
                }
              </div>
            </Col>
            <Col sm={12}>
              <Row>
                <Col xs={12}>
                  <div className="mb-3">
                    <Label className="form-label">{props.t("Sub Category")}</Label>
                    <Input
                      name="subCatName"
                      type="text"
                      value={validation.values.subCatName || ""}
                      invalid={
                        validation.touched.subCatName &&
                          validation.errors.subCatName
                          ? true
                          : false
                      }
                      placeholder={props.t("Enter Sub-Category Name")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                    />
                    {validation.touched.subCatName &&
                      validation.errors.subCatName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.subCatName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-end">
                <button type="submit" className="btn btn-success save-user">
                  <i className="mdi mdi-check-circle-outline me-1" />
                  {props.t("Save")}
                </button>
                <Button
                  type="button"
                  color="secondary"
                  className="mx-1"
                  onClick={handleCancel}
                >
                  <i className="mdi mdi-cancel me-1" />
                  {props.t("Cancel")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

SubCategoryModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SubCategoryModal));
