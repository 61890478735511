const labelMirror = {
    // DB label : UI label
    "OrderType": "Order type",
    "TotalPrice": "Total",
    "totalafterDiscount": "After discount",
    "waiter_name": "Waiter name",
    "ItemName": "Name",
    "ItemPrice": "Item price",
    "TotalItemPrice": "Total item price",
    "CategoryName": "Category",
    "Qty": "Quatity",
    "totalQty": "Quatity",
    "TotalSales": "Total",
    "PayDate": "Date",
    "Description": "Spending",
    "EmpName": "Employee",
    "OpeningBalance": "Previous credit",
    "Balance": "Post credit",
    "LogDate": "Date",
    "Owned": "Owed to him",
    "PaidUp": "Bill description",
    "DayDate": "Date",
    "BankDeposit": "Deposit",
    "MainSafeBuyBill": "Purchase invoices",
    "MainSafeSpendings": "Expanses safe",
    "BankTake": "Withdrawal",
    "Spendings": "Spending",
    "SupplierName": "Supplier",
    "BillNo": "Bill no",
    "SafeName": "Name"
};

const convertToUiLabel = (label) => {
    return labelMirror[label] || label;
};

export { convertToUiLabel };