import axiosInstance from "../axios/axiosInstance";

export async function get(url, config = {})  {
    return await axiosInstance.get(url, { ...config })
    .then((response) => response.data)
}

export async function post(url, data, config = {})  {
    return await axiosInstance.post(url, data)
    .then((response) => response)
}