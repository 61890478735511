import {
    attachPdfTabelDataToDoc,
    prepareTabelData,
    getTabelRowData,
    checkForRemainingSpacePage,
    convertToUiLabel
} from "./";

const preparePdfTabelForShiftReport = (doc, data, filteredData, margin) => {
    let marginTop = margin;

    // Add a Tabel to the PDF
    data.Table1.map((obj, i) => {
        // TABEL1
        const Table1 = [];
        const { currentObj } = getTabelRowData(filteredData.Table1, obj, i);
        [currentObj].forEach((obj, i) => {
            Table1[i] = Object.values(obj);
        });
        Table1.unshift(filteredData.Table1);

        // reset font
        doc.setFont("helvetica", "bold");
        doc.setTextColor(251, 148, 14);

        function addTextToDoc(text, x, y, margin, goToNextPage) {
            doc.text(text, x, y);
            const { modifiedMarginTop } = checkForRemainingSpacePage(
                doc,
                margin,
                (y + margin),
                goToNextPage
            );
            marginTop = modifiedMarginTop;
        }

        Table1[0].forEach((one, i) => {
            const { modifiedMarginTop } = checkForRemainingSpacePage(
                doc,
                marginTop,
                marginTop,
                true
            );
            marginTop = modifiedMarginTop;
            if (i % 2 === 0) {
                addTextToDoc(`${convertToUiLabel(one)}: ${Table1[1][i]}`, 15, marginTop, 0, false);
                if (Table1[0][i + 1]) {
                    addTextToDoc(`${convertToUiLabel(Table1[0][i + 1])}: ${Table1[1][i + 1]}`, 100, marginTop, 0, true);
                }
                marginTop += 5; // Increase Y coordinate for the next text with a margin
            }
        });

        // reset font
        doc.setFont("helvetica", "normal");
        doc.setTextColor(0, 0, 0);

        // TABLE
        const arg1 = data.Table2
            .filter(item => item.MasterID === obj.PK_OrderMaster_ID);
        const arg2 = filteredData.Table2;
        const { TabelData } = prepareTabelData(arg1, arg2);
        const { doc: returnedDoc } = attachPdfTabelDataToDoc(doc, TabelData, marginTop);
        doc = returnedDoc;

        marginTop = doc.autoTable.previous.finalY + 20;
    });

    return doc;
};

export {
    preparePdfTabelForShiftReport
};
