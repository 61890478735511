import {
  GET_UNITS_SUCCESS,
  GET_UNITS_FAIL,
  POST_UNIT_SUCCESS,
  POST_UNIT_FAIL,
} from "./actionTypes"


const INIT_STATE = {
    allUnits: [],
    // userProfile: {},
    error: {},
  }
  
  const units = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_UNITS_SUCCESS:
        return {
          ...state,
          allUnits: action.payload,
        }
  
      case GET_UNITS_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case POST_UNIT_SUCCESS:
        return {
          ...state,
          allUnits: [...state.allUnits, action.payload],
        }
  
      case POST_UNIT_FAIL:
        return {
          ...state,
          error: { ...state, error: action.payload },
        }
  
      default:
        return state
    }
  }
  
  export default units
  