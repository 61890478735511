import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  Spinner
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

import { useFormik } from "formik";
import * as Yup from "yup";
import classes from "./index.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { getShifEmplyees, getShifDetails } from "store/end-shift/actions";
import ManagerApproval from "./ManagerApproval";
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf";

const EndShift = (props) => {
  const dispatch = useDispatch();
  const [inputValues, setInputValuse] = useState({
    "Total_Orders": 0,
    "After_Discount": 0,
    "Total_Cash": 0,
    "Total_Visa": 0,
    "Total_Companies": 0,
    "Total_Host": 0,
    "Orders_Count": 0,
    "Returns_Count": 0,
    "Total_Return": 0,
    "Expenses_Count": 0,
    "Total_Expenses": 0,
    "Total_Unpaid_Delivery": 0,
    "Total_Unpaid_Tables": 0,
    "Value_Carried_Over_From": 0,
    "Value_Carried_Over_To": 0,
    "Net_Cash": 0,
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedEmp: "",
    },
    validationSchema: Yup.object({
      selectedEmp: Yup.string().required("Please select Employee."),
    }),
    onSubmit: values => {
      // 
    },
  });

  const shiftDetailsReducer = useSelector((state) => state.shiftDetails);
  const { shiftDetails, shiftEmployees, loadingShiftDetails: loading } = shiftDetailsReducer;

  // Fetch shift employees
  useEffect(() => {
    dispatch(getShifEmplyees());
  }, []);

  // Fetch shift details
  useEffect(() => {
    if (!validation.values.selectedEmp) return;
    dispatch(getShifDetails(validation.values.selectedEmp));
  }, [validation.values.selectedEmp]);

  // Set shift details
  useEffect(() => {
    if (shiftDetails.length === 0) return;
    setInputValuse({
      "Total_Orders": shiftDetails.Table[0].price || 0,
      "After_Discount": shiftDetails.Table[0].TotalAfterDiscount || 0,
      "Total_Cash": shiftDetails.Table[0].Cashprice || 0,
      "Total_Visa": shiftDetails.Table[0].Visaprice || 0,
      "Total_Companies": shiftDetails.Table[0].Componyprice || 0,
      "Total_Host": shiftDetails.Table3[0].ordercount || 0,
      "Orders_Count": shiftDetails.Table[0].ordercount || 0,
      "Returns_Count": shiftDetails.Table1[0].returncount || 0,
      "Total_Return": shiftDetails.Table1[0].returnedvalue || 0,
      "Expenses_Count": shiftDetails.Table2[0].expensecount || 0,
      "Total_Expenses": shiftDetails.Table2[0].price || 0,
      "Total_Unpaid_Delivery": shiftDetails.Table6[0].NotPaidDelivery || 0,
      "Total_Unpaid_Tables": shiftDetails.Table7[0].NotPaidTables || 0,
      "Value_Carried_Over_From": shiftDetails.Table8[0].TransferedFrom || 0,
      "Value_Carried_Over_To": shiftDetails.Table9[0].TransferedTo || 0,
      "Net_Cash": shiftDetails.Table10[0].NetCash || 0,
    });
  }, [shiftDetails]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Shift")}
            breadcrumbItem={props.t("End Shift")}
          />
          <Card>
            <CardBody>
              <Form
                onSubmit={e => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col sm="3">
                    <div className="mb-3">
                      <Label>{props.t("Employee")}</Label>
                      <Input
                        name="selectedEmp"
                        type="select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.selectedEmp || ""}
                        invalid={
                          validation.touched.selectedEmp &&
                            validation.errors.selectedEmp
                            ? true
                            : false
                        }
                      >
                        <option value="" disabled>
                          {props.t("Select Employee")}
                        </option>
                        {shiftEmployees && shiftEmployees.length > 0 &&
                          shiftEmployees
                            .filter(one => one.job_category === "كاشير")
                            .map((emp, index) => (
                              <option key={index} value={emp.FK_EmpID}>
                                {emp.EmpName}
                              </option>
                            ))}
                      </Input>
                      {validation.touched.selectedEmp &&
                        validation.errors.selectedEmp ? (
                        <FormFeedback type="invalid">
                          {validation.errors.selectedEmp}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Form>
              <Row>
                {loading
                  ? <div className="text-center py-5"><Spinner className="ms-2" color="primary" /></div>
                  : <>
                    {Object.entries(inputValues).map(([key, value], i) => (
                      <Col key={i} md={3} xs={6}>
                        <div className="mb-3">
                          <Label className={`${classes.res_font}`}>
                            {props.t(key.replace(/_/g, " "))}
                          </Label>
                          <div className={`${classes.inputview}`}>
                            {value}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </>
                }
              </Row>
              <div className="text-end">
                {!loading && validation.values.selectedEmp &&
                  < ManagerApproval selectedEmp={validation.values.selectedEmp} />
                }
                {!loading && validation.values.selectedEmp ?
                  <SendAndPrintPdf
                    extraData={{
                      "Employee": shiftEmployees && validation.values.selectedEmp
                        ? shiftEmployees.find(emp => emp.PK_Shift_ID === +validation.values.selectedEmp)
                          ? shiftEmployees.find(emp => emp.PK_Shift_ID === +validation.values.selectedEmp).EmpName
                          : ""
                        : "",
                    }}
                    data={Object.entries(inputValues).map(one => ({ [one[0]]: one[1] }))}
                    filteredData={null}
                  />
                  : <></>
                }
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

EndShift.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(EndShift));
