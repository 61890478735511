import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import i18n from "i18n";

import { useDispatch, useSelector } from "react-redux";
import {
    setEndShiftEmpId,
    setEndShiftEmpIdReset,
    getManagerApprovalReset
} from "store/end-shift/actions";
import ManagerApprovalModal from "./ManagerApprovalModal";

const ManagerApproval = (props) => {
    const { selectedEmp } = props;
    const dispatch = useDispatch();
    const [openManagerApprovalModel, setOpenManagerApprovalModel] = useState(false);
    const openManagerApprovalModal = () => {
        if (!selectedEmp) {
            toast.dismiss();
            toast.error(i18n.t("Please select employee"));
            return;
        }
        setOpenManagerApprovalModel(true);
    };

    const shiftDetails = useSelector((state) => state.shiftDetails);
    const { managerApproval, endShiftEmpId } = shiftDetails;

    // make sure if not selectedEmp and model is opened close model
    useEffect(() => {
        if (!selectedEmp && openManagerApprovalModel) {
            setOpenManagerApprovalModel(false);
        }
    }, [selectedEmp]);

    // send end shift when we get manager approval
    useEffect(() => {
        if (managerApproval.length === 0) return;
        if (selectedEmp &&
            managerApproval[0].Response === "Approved" &&
            managerApproval[0].EmpID
        )
            dispatch(setEndShiftEmpId({
                empID: +selectedEmp,
                empToID: managerApproval[0].EmpID
            }));
    }, [managerApproval]);


    // reset manager approval && end shift
    useEffect(() => {
        if (typeof endShiftEmpId !== "string") return;
        toast.dismiss();
        toast.success(i18n.t("Shift is ended successfully. window will close in 1 second"));

        const timer = setTimeout(() => {
            setOpenManagerApprovalModel(false);
            dispatch(setEndShiftEmpIdReset());
            dispatch(getManagerApprovalReset());
        }, 1000);

        return () => clearTimeout(timer);
    }, [endShiftEmpId]);

    return (
        <>
            <button
                type="submit"
                className="btn btn-primary save-user mx-1 my-2"
                onClick={openManagerApprovalModal}
            >
                {props.t("Manager Approval")}
            </button>
            <ManagerApprovalModal
                isOpen={openManagerApprovalModel}
                closeModel={() => setOpenManagerApprovalModel(false)}
            />
        </>
    );
};

ManagerApproval.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(ManagerApproval));
