import React, { useState } from "react";
const useSelectInputErrMsg = (props) => {
    const errorMsgs = {
        mainCategory: props.t('Please select main category'),
        subCategory: props.t('Please select sub category'),
        prepareArea: props.t('Please select prepare area'),
        material: props.t('Please select material'),
        component: props.t('Please select component'),
    };
    const [showMainCategoryErrMsg, setShowMainCategoryErrMsg] = useState(false);
    const [showSubCategoryErrMsg, setShowSubCategoryErrMsg] = useState(false);
    const [showPrepareAreaErrMsg, setShowPrepareAreaErrMsg] = useState(false);

    // models
    const [subCategoryModelShowMainCategoryErrMsg, setSubCategoryModelShowMainCategoryErrMsg] = useState(false);
    const [ingrediantModalShowMaterialErrMsg, setIngrediantModalShowMaterialErrMsg] = useState(false);
    const [ingrediantModalShowComponentErrMsg, setIngrediantModalShowComponentErrMsg] = useState(false);

    return {
        errorMsgs: errorMsgs,
        states: {
            showMainCategoryErrMsg,
            showSubCategoryErrMsg,
            showPrepareAreaErrMsg,
            subCategoryModelShowMainCategoryErrMsg,
            ingrediantModalShowMaterialErrMsg,
            ingrediantModalShowComponentErrMsg
        },
        sets: {
            setShowMainCategoryErrMsg,
            setShowSubCategoryErrMsg,
            setShowPrepareAreaErrMsg,
            setSubCategoryModelShowMainCategoryErrMsg,
            setIngrediantModalShowMaterialErrMsg,
            setIngrediantModalShowComponentErrMsg,
        },
    };
};

export default useSelectInputErrMsg;

// useSelectInputErrMsg.propTypes = {
//     location: PropTypes.object,
//     t: PropTypes.any,
// };

// export default withRouter(withTranslation()(useSelectInputErrMsg));
