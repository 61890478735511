import {
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  POST_CATEGORY,
  POST_CATEGORY_SUCCESS,
  POST_CATEGORY_FAIL,
} from "./actionTypes"

// Get material actions
export const getCategories = () => ({
  type: GET_CATEGORIES,
})

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
})

export const getCategoriesFail = err => ({
  type: GET_CATEGORIES_FAIL,
  payload: err,
})

// Post category actions
export const postCategory = cat => ({
  type: POST_CATEGORY,
  payload: cat,
})

export const postCategorySuccess = material => ({
  type: POST_CATEGORY_SUCCESS,
  payload: material,
})

export const postCategoryFail = err => ({
  type: POST_CATEGORY_FAIL,
  payload: err,
})
