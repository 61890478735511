import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import classes from "../index.module.scss";
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { useFormik } from "formik";
import * as Yup from "yup";
import SimpleBar from "simplebar-react";
import UnitModal from "./UnitModal";
import MaterialModal from "./MaterialModal";
import { getMaterials } from "../../../store/products/materials/actions";
import { postComponent, getUnits } from "store/actions";
import { get } from "helpers/axios_auth_api";
import SelectInputErrorMessage from "../SelectInputErrorMessage";
import useSelectInputErrMsg from "../hooks/use-selectInputErrMsg";

const ComponentModal = props => {
  const { componentModal, handleComponentToggle, handleComponentAdded } = props;
  const dispatch = useDispatch();
  const { errorMsgs, sets, states } = useSelectInputErrMsg({ t: props.t });
  const newComponentAdded = useSelector(
    state => state.components.newComponentAdded
  );

  const materials = useSelector(state => state.materials.allMaterials);
  const units = useSelector(state => state.units.allUnits);

  const [componentName, setComponentName] = useState("");
  const [componentQty, setComponentQty] = useState(0);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [unitList, setUnitList] = useState([]);

  const [materialList, setMaterialList] = useState([]);

  const [unitModal, setUnitModal] = useState(false);
  const [materialQuantity, setMaterialQty] = useState(0);
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [materialModal, setMaterialModal] = useState(false);
  const [unitUpdated, setUnitUpdated] = useState(false);
  const [materialUpdated, setMaterialUpdated] = useState(false);

  const [selectedMaterialName, setSelectedMaterialName] = useState("");
  // List of Ingrediants
  const [tableRows, setTableRows] = useState([]);
  const [oldTableRow, setOldTableRow] = useState([]);
  const [isSubmitDisable, setIsSubmitDisable] = useState(false);

  // Get Unit Data

  // const fetchUnits = React.useCallback(async () => {
  //   const fetchedData = await get(
  //     "TblUnit/getPage?pageSize=100"
  //   );
  //   setUnitList(fetchedData.rs.data);
  // });

  const handleUnitUpdated = () => {
    setUnitUpdated(!unitUpdated);
  };

  useEffect(() => {
    if (units && !units.length) {
      dispatch(getUnits());
    }
  }, [dispatch, units]);

  useEffect(() => {
    if (newComponentAdded) {
      handleComponentAdded(newComponentAdded);
      setIsSubmitDisable(false);
      validation.resetForm();
      setComponentName("");
      setComponentQty(0);
      setSelectedUnit("");
      setTableRows([]);
      toggle();
    }
  }, [newComponentAdded]);

  useEffect(() => {
    // fetchUnits();
    setUnitList(units);
  }, [unitUpdated, units]);

  // Get Material Data

  const handleMaterialUpdated = () => {
    setMaterialUpdated(!materialUpdated);
  };

  // Get All Material From Api
  useEffect(() => {
    dispatch(getMaterials());
  }, [materialUpdated]);

  useEffect(() => {
    let materialListCopy = [];
    materials &&
      materials.map(
        item =>
          !item.material && item.materialName && materialListCopy.push(item)
      );
    setMaterialList(materialListCopy);
  }, [materials]);

  function handleRemoveRow(id) {
    var modifiedRows = [...tableRows];
    modifiedRows = modifiedRows.filter(x => x["id"] !== id);
    setTableRows(modifiedRows);
  }

  const toggle = () => {
    handleComponentToggle();
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      componentName: componentName || "",
      componentQty: componentQty || "",
      selectedUnit: selectedUnit || "",
    },
    validationSchema: Yup.object({
      componentName: Yup.string().required(
        props.t("Please Enter Component Name")
      ),
      componentQty: Yup.string().required(
        props.t("Please Enter Component Qty")
      ),
      selectedUnit: Yup.number().required(props.t("Please select unit")),
    }),
    onSubmit: values => {
      const materials = handleMaterialListToSend();
      const newComponent = {
        materialName: values["componentName"],
        fk_Unit_ID: values["selectedUnit"],
        // componentQty: values["componentQty"],
        materialComponent: materials,
      };

      // save new user
      dispatch(postComponent(newComponent));
      setIsSubmitDisable(true);


    },
  });
  const handleMaterialListToSend = () => {
    let materialListToSend = [];
    for (let element of tableRows) {
      element.id = 0;
      element.mokQty = componentQty;
      delete element["materialName"];
      materialListToSend.push(element);
    }
    return materialListToSend;
  };
  const materialValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      selectedMaterialId: selectedMaterial || "",
      selectedMaterialName: selectedMaterialName || "",
      materialQuantity: materialQuantity || "",
    },
    validationSchema: Yup.object({
      selectedMaterialId: Yup.number().required(
        props.t("Please Select Material")
      ),
      materialQuantity: Yup.number().required(
        props.t("Please enter material quantity")
      ),
    }),
    onSubmit: values => {
      const newMaterial = {
        fkComponent: values["selectedMaterialId"],
        materialName: values["selectedMaterialName"],
        qty: values["materialQuantity"],
      };
      addMaterialToList(newMaterial);
      materialValidation.resetForm();
      setSelectedMaterial("");
      setMaterialQty("");

      // // toggle()
    },
  });

  const resetForm = () => {
    setSelectedMaterial("");
    setMaterialQty("");
  };

  const handleSelectUnit = e => {
    setSelectedUnit(Number(e.target.value));
  };

  const handleCancel = () => {
    resetForm();
    setTableRows(oldTableRow);
    validation.resetForm();
    toggle();
  };

  const handleUnitModal = () => {
    setUnitModal(true);
  };
  const handleUnitToggle = () => {
    setUnitModal(!unitModal);
  };

  function handleSelectMaterial(e) {
    setSelectedMaterial(Number(e.target.value));
    materialList.map(
      option =>
        option.pkMaterialId === Number(e.target.value) &&
        setSelectedMaterialName(option.materialName)
    );
  }

  const handleMaterialModal = () => {
    setMaterialModal(true);
  };

  const handleMaterialToggle = () => {
    setMaterialModal(!materialModal);
  };

  const addMaterialToList = newMaterial => {
    // setOldTableRow(tableRows)
    let id = 0;
    const modifiedIngList = [...tableRows];
    modifiedIngList.map(ingItem => (ingItem.id = ++id));
    newMaterial.id = modifiedIngList.length + 1;
    modifiedIngList.push(newMaterial);
    setTableRows(modifiedIngList);
  };

  return (
    <Modal isOpen={componentModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Add Components")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col xs={6}>
              <div className="mb-3">
                <Label>Component</Label>
                <Input
                  name="componentName"
                  type="text"
                  placeholder={props.t("Enter component name")}
                  onChange={e => setComponentName(e.target.value)}
                  onBlur={validation.handleBlur}
                  value={validation.values.componentName || ""}
                  invalid={
                    validation.touched.componentName &&
                      validation.errors.componentName
                      ? true
                      : false
                  }
                />
                {validation.touched.componentName &&
                  validation.errors.componentName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.componentName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Quantity")}</Label>
                <Input
                  name="componentQty"
                  type="number"
                  placeholder={props.t("Enter Quantity")}
                  onChange={e => setComponentQty(Number(e.target.value))}
                  onBlur={validation.handleBlur}
                  value={validation.values.componentQty || ""}
                  invalid={
                    validation.touched.componentQty &&
                      validation.errors.componentQty
                      ? true
                      : false
                  }
                  min={0}
                />
                {validation.touched.componentQty &&
                  validation.errors.componentQty ? (
                  <FormFeedback type="invalid">
                    {validation.errors.componentQty}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="mb-3">
                <label className="form-label">{props.t("Default Unit")}</label>
                <div className="input-group">
                  <div className="col-10">
                    <Input
                      name="selectedUnit"
                      type="select"
                      onChange={e => {
                        handleSelectUnit(e);
                      }}
                      onBlur={validation.handleBlur}
                      value={validation.values.selectedUnit || ""}
                      invalid={
                        validation.touched.selectedUnit &&
                          validation.errors.selectedUnit
                          ? true
                          : false
                      }
                    >
                      <option value="">{props.t("Select")}</option>
                      {unitList.map((unit, index) => (
                        <option key={index} value={unit.pkUnitId}>
                          {unit.unit}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.selectedUnit &&
                      validation.errors.selectedUnit ? (
                      <FormFeedback type="invalid">
                        {validation.errors.selectedUnit}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="col-2">
                    <button
                      className="btn btn-primary w-100"
                      style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      type="button"
                      onClick={handleUnitModal}
                    >
                      {props.t("Add")}
                    </button>
                  </div>
                  {validation.touched.selectedUnit &&
                    validation.errors.selectedUnit ? (
                    <FormFeedback type="invalid">
                      {validation.errors.selectedUnit}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <div className="mb-3">
                <Label>{props.t("Material")}</Label>
                <div className="input-group">
                  <Input
                    name="selectedMaterialId"
                    type="select"
                    onChange={e => {
                      handleSelectMaterial(e);
                    }}
                    onBlur={materialValidation.handleBlur}
                    value={materialValidation.values.selectedMaterialId || ""}
                    invalid={
                      materialValidation.touched.selectedMaterialId &&
                        materialValidation.errors.selectedMaterialId
                        ? true
                        : false
                    }
                  >
                    <option value="">{props.t("Select")}</option>
                    {materialList.map(material => (
                      <option
                        key={material.pkMaterialId}
                        value={material.pkMaterialId}
                      >
                        {material.materialName}
                      </option>
                    ))}
                  </Input>

                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleMaterialModal}
                  >
                    {props.t("Add")}
                  </button>
                  {materialValidation.touched.selectedMaterialId &&
                    materialValidation.errors.selectedMaterialId ? (
                    <FormFeedback type="invalid">
                      {materialValidation.errors.selectedMaterialId}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>

            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Quantity")}</Label>
                <div className="input-group">
                  <Input
                    name="materialQuantity"
                    type="number"
                    placeholder={props.t("Enter Quantity")}
                    onChange={e => setMaterialQty(Number(e.target.value))}
                    onBlur={materialValidation.handleBlur}
                    value={materialValidation.values.materialQuantity || ""}
                    invalid={
                      materialValidation.touched.materialQuantity &&
                        materialValidation.errors.materialQuantity
                        ? true
                        : false
                    }
                    min={0}
                  />
                  <button
                    className="btn btn-primary mx-1"
                    onClick={e => {
                      e.preventDefault();
                      materialValidation.handleSubmit();
                      return false;
                    }}
                    type="button"
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    {props.t("Add Ingrediant")}
                  </button>
                  {materialValidation.touched.materialQuantity &&
                    materialValidation.errors.materialQuantity ? (
                    <FormFeedback type="invalid">
                      {materialValidation.errors.materialQuantity}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <div className="mt-4 mx-auto">
            <SimpleBar
              style={{ maxHeight: "195px", width: "75%", margin: "0 auto" }}
            >
              <div className="table-responsive">
                <Table className="table table-nowrap align-middle table-hover mb-0">
                  <tbody>
                    {(tableRows || []).map((tableRow, key) => (
                      <tr key={key}>
                        <td>
                          <h5 className="text-truncate font-size-14 mb-1">
                            {tableRow.materialName}
                          </h5>
                        </td>
                        <td>
                          <h5 className="text-truncate font-size-14 mb-1">
                            {tableRow.qty}
                          </h5>
                        </td>
                        <td style={{ width: "90px" }}>
                          <div>
                            <ul className="list-inline mb-0 font-size-16">
                              <li className="list-inline-item">
                                <Link
                                  onClick={() => handleRemoveRow(tableRow.id)}
                                  className="text-danger p-1 border-none"
                                >
                                  <i className="bx bxs-trash" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </SimpleBar>
          </div>

          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="submit"
                  className="btn btn-success save-user"
                  disabled={isSubmitDisable}
                >
                  {isSubmitDisable ? (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  ) : (
                    <i className="mdi mdi-check-circle-outline me-1" />
                  )}
                  {props.t("Save")}
                </button>
                <Button
                  type="button"
                  color="secondary"
                  className="mx-1"
                  onClick={handleCancel}
                >
                  <i className="mdi mdi-cancel me-1" />
                  {props.t("Cancel")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        <UnitModal
          unitModal={unitModal}
          handleUnitToggle={handleUnitToggle}
          handleUnitUpdated={handleUnitUpdated}
        />
        <MaterialModal
          materialModal={materialModal}
          handleMaterialToggle={handleMaterialToggle}
          handleMaterialUpdated={handleMaterialUpdated}
        />
      </ModalBody>
    </Modal>
  );
};

ComponentModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(ComponentModal));
