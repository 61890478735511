import axios from "axios";


export default axios.create({
    baseURL: "http://restaurantapitest.smartvillageqatar.com/",
    Accept: "application/json",
    "Content-Type": "application/json"
})


