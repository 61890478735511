import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import { withTranslation } from "react-i18next"
import Select from "react-select"

import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Form,
  Button,
  Table,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import { useFormik } from "formik"
import * as Yup from "yup"
import SimpleBar from "simplebar-react"
import classes from "../index.module.scss"
import MaterialModal from "./MaterialModal"
import ComponentModal from "./ComponentModal"
import { getMaterials } from "../../../store/products/materials/actions"
import { getComponents } from "../../../store/products/components/actions"
import useSelectInputErrMsg from "../hooks/use-selectInputErrMsg"
import SelectInputErrorMessage from "../SelectInputErrorMessage"
import { selectInputBgColors, layoutModeTypes } from "constants/layout"

const IngrediantModal = props => {
  const {
    ingrediantModal,
    handleIngrediantToggle,
    tableRows,
    handleIngrediantsList,
  } = props
  const dispatch = useDispatch()
  const { errorMsgs, sets, states } = useSelectInputErrMsg({ t: props.t })

  const materials = useSelector(state => state.materials.allMaterials)
  const { layoutModeType } = useSelector(state => state.Layout)
  const newComponentAdded = useSelector(
    state => state.components.newComponentAdded
  )
  const components = useSelector(state => state.components.allComponents)

  const [selectedMaterial, setSelectedMaterial] = useState(null)
  const [selectedComponent, setSelectedComponent] = useState("")
  const [materialQuantity, setMaterialQty] = useState(0)
  const [componentQuantity, setComponentQuantity] = useState(0)

  const [materialModal, setMaterialModal] = useState(false)
  const [componentModal, setComponentModal] = useState(false)

  const [materialList, setMaterialList] = useState([])

  const [componentsList, setComponentsList] = useState([])
  const [compAdded, setCompAdded] = useState(false)

  // reset select states in first render
  useEffect(() => {
    sets.setIngrediantModalShowMaterialErrMsg(false)
    sets.setIngrediantModalShowComponentErrMsg(false)
  }, [])

  // Get All Material From Api
  useEffect(() => {
    if (materials && !materials.length) {
      dispatch(getMaterials())
    }
  }, [materials, dispatch])

  useEffect(() => {
    if(newComponentAdded) {
      setCompAdded(true)
    } 
  }, [newComponentAdded, componentsList])
  
  useEffect(() => {
    if(compAdded) {
      componentsList.map(item => item.pkMaterialId === newComponentAdded && setSelectedComponent(item))
      setCompAdded(false)

    }
  }, [compAdded])

  // Get All components from
  // useEffect(() => {
  //   if (components && !components.length) {
  //     dispatch(getComponents())
  //   }
  //   if (materialList.length > 0) {
  //     // addMaterialNameToComponent()
  //   }
  // }, [components])

  // useEffect(() => {
  //   if (components ) addMaterialNameToComponent();
  // }, [components]);

  useEffect(() => {
    handleMaterialList()
    handleComponenetList()
  }, [materials])

  const handleMaterialList = () => {
    let materialListCopy = []
    materials &&
      materials.map(item => {
        item.materialName && !item.material && materialListCopy.push(item)
      })
    setMaterialList(materialListCopy)
  }

  const handleComponenetList = () => {
    let componentListCopy = []
    materials &&
      materials.map(item => {
        item.materialName && item.material && componentListCopy.push(item)
      })
    setComponentsList(componentListCopy)
  }

  // useEffect(() => {
  //   addMaterialNameToComponent()
  // }, [components, materialList])

  // const addMaterialNameToComponent = () => {
  //   let componentCopy = []
  //   componentCopy = [...components]
  //   if (components && componentCopy.length > 0) {
  //     for (let component of componentCopy) {
  //       for (let material of materialList) {
  //         if (component) {
  //           if (component.fkMaterialId === material.pkMaterialId) {
  //             component.materialName = material.materialName
  //           }
  //         }
  //       }
  //     }
  //   }
  //   setComponentsList(componentCopy)
  // }

  const handleMaterialModal = () => {
    setMaterialModal(true)
  }

  const handleMaterialToggle = () => {
    setMaterialModal(!materialModal)
  }

  const handleComponentModal = () => {
    setComponentModal(true)
  }
  const handleComponentToggle = () => {
    setComponentModal(!componentModal)
  }

  const handleComponentAdded = () => {
    // setComponentChanged(!componentChanged)
    // dispatch(getMaterials())
  }


  function handleRemoveRow(id) {
    var modifiedRows = [...tableRows]
    // modifiedRows = modifiedRows.filter(x => x.FK_MaterialID !== id)
    modifiedRows = modifiedRows.filter(x => x.id !== id)
    handleIngrediantsList(modifiedRows)
    // setTableRows(modifiedRows)
  }

  const toggle = () => {
    handleIngrediantToggle()
  }

  const addMaterialToList = newMaterial => {
    let id = 0
    const modifiedIngList = [...tableRows]
    modifiedIngList.map(ingItem => (ingItem.id = ++id))
    newMaterial.id = modifiedIngList.length + 1
    modifiedIngList.push(newMaterial)
    // setTableRows(modifiedIngList)
    handleIngrediantsList(modifiedIngList)
  }

  // validation
  const materialValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      selectedMaterial: selectedMaterial || "",
      materialQuantity: Number(materialQuantity) || "",
      selectedMaterialId:
        (selectedMaterial && selectedMaterial.pkMaterialId) || "",
      MaterialName: (selectedMaterial && selectedMaterial.materialName) || "",
      itemId: (tableRows.length > 0 && tableRows[0].FK_ItemID) || "",
      ItemName: (tableRows.length > 0 && tableRows[0].ItemName) || "",
    },
    validationSchema: Yup.object({
      selectedMaterial: Yup.object().required(
        props.t("Please Select Material")
      ),
      materialQuantity: Yup.number().required(
        props.t("Please enter material quantity")
      ),
    }),
    onSubmit: values => {
      const newMaterial = {
        FK_ItemID: values["itemId"],
        FK_MaterialID: values["selectedMaterialId"],
        ItemName: values["ItemName"],
        MatType: "Raw",
        MaterialName: values["MaterialName"],
        Qty: values["materialQuantity"],
      }
      // save new user
      //dispatch(onAddNewUser(newMaterial))
      addMaterialToList(newMaterial)
      materialValidation.resetForm()
      setMaterialQty(0)
      setSelectedMaterial("")
      // setResetMaterialValue(false)

      // reset selected input
      sets.setIngrediantModalShowMaterialErrMsg(false)
    },
  })

  const componentValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      selectedComponent: selectedComponent || "",
      componentQuantity: Number(componentQuantity) || "",
      selectedMaterialId:
        (selectedComponent && selectedComponent.fkMaterialId) || "",
      MaterialName: (selectedComponent && selectedComponent.materialName) || "",
      itemId: (tableRows.length > 0 && tableRows[0].FK_ItemID) || "",
      ItemName: (tableRows.length > 0 && tableRows[0].ItemName) || "",
      componentId: (selectedComponent && selectedComponent.componentId) || "",
    },
    validationSchema: Yup.object({
      selectedComponent: Yup.object().required(
        props.t("Please Select Material")
      ),
      componentQuantity: Yup.number().required(
        props.t("Please Enter Quantity")
      ),
    }),
    onSubmit: values => {
      const newMaterial = {
        FK_ItemID: values["itemId"],
        FK_MaterialID: values["selectedMaterialId"],
        ItemName: values["ItemName"],
        MatType: "Raw",
        MaterialName: values["MaterialName"],
        Qty: values["componentQuantity"],
        componentId: values["componentId"],
      }
      // save new user
      //dispatch(onAddNewUser(newMaterial))
      addMaterialToList(newMaterial)
      componentValidation.resetForm()
      setComponentQuantity(0)
      setSelectedComponent("")

      // toggle()

      // reset selected input
      sets.setIngrediantModalShowComponentErrMsg(false)
    },
  })

  function handleSelectMaterial(e) {
    setSelectedMaterial(e)
  }

  function handleSelectedComponent(e) {
    setSelectedComponent(e)
  }

  return (
    <Modal isOpen={ingrediantModal} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        {props.t("Add Ingrediants")}
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            // // if select field are empty make sure to show error msg
            sets.setIngrediantModalShowMaterialErrMsg(true)

            materialValidation.handleSubmit()
            return false
          }}
        >
          <Row>
            <Col sm={6}>
              <div className="mb-3">
                <Label>{props.t("Material")}</Label>
                <div className="input-group">
                  {/* value={materialList.find(function (option) {
                      return option.value === selectedMaterial
                    })} */}
                  <Select
                    value={
                      selectedMaterial !== ""
                        ? materialList.find(function (option) {
                            return selectedMaterial
                              ? option.pkMaterialId ===
                                  Number(selectedMaterial.pkMaterialId)
                              : ""
                          })
                        : ""
                    }
                    onChange={e => {
                      handleSelectMaterial(e)
                    }}
                    options={materialList}
                    className={`select2-selection form-control 
                    ${
                      states.ingrediantModalShowMaterialErrMsg &&
                      !selectedMaterial &&
                      classes.red_border
                    } 
                    ${classes.input_with_btn} select-material`}
                    getOptionLabel={materialList =>
                      materialList["materialName"]
                    }
                    getOptionValue={materialList =>
                      materialList["pkMaterialId"]
                    }
                    name="selectedMaterial"
                    onFocus={() =>
                      sets.setIngrediantModalShowMaterialErrMsg(false)
                    }
                    onBlur={() =>
                      sets.setIngrediantModalShowMaterialErrMsg(true)
                    }
                    styles={{
                      menu: base => ({
                        ...base,
                        backgroundColor:
                          layoutModeType === layoutModeTypes.LIGHT
                            ? selectInputBgColors.light
                            : selectInputBgColors.dark,
                      }),
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleMaterialModal}
                  >
                    {props.t("Add")}
                  </button>
                  {states.ingrediantModalShowMaterialErrMsg &&
                    !selectedMaterial && (
                      <SelectInputErrorMessage>
                        {errorMsgs.material}
                      </SelectInputErrorMessage>
                    )}
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Quantity")}</Label>
                <div className="input-group">
                  <Input
                    name="materialQuantity"
                    type="number"
                    placeholder={props.t("Enter Quantity")}
                    onChange={e => setMaterialQty(e.target.value)}
                    onBlur={materialValidation.handleBlur}
                    value={materialValidation.values.materialQuantity || ""}
                    invalid={
                      materialValidation.touched.materialQuantity &&
                      materialValidation.errors.materialQuantity
                        ? true
                        : false
                    }
                    min={0}
                  />
                  <button className="btn btn-primary mx-1" type="submit">
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    {props.t("Add Ingrediant")}
                  </button>
                  {materialValidation.touched.materialQuantity &&
                  materialValidation.errors.materialQuantity ? (
                    <FormFeedback type="invalid">
                      {materialValidation.errors.materialQuantity}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
        </Form>

        <Form
          onSubmit={e => {
            e.preventDefault()
            componentValidation.handleSubmit()
            // if select field are empty make sure to show error msg
            sets.setIngrediantModalShowComponentErrMsg(true)

            return false
          }}
        >
          <Row>
            <Col sm={6}>
              <div className="mb-3">
                <Label>{props.t("Component")}</Label>
                <div className="input-group">
                  <Select
                    value={
                      selectedComponent !== ""
                        ? componentsList.find(function (option) {
                            return selectedComponent
                              ? option.pkMaterialId ===
                                  Number(selectedComponent.pkMaterialId)
                              : ""
                          })
                        : ""
                    }
                    onChange={e => {
                      handleSelectedComponent(e)
                    }}
                    options={componentsList}
                    className={`select2-selection form-control 
                    ${
                      states.ingrediantModalShowComponentErrMsg &&
                      !selectedComponent &&
                      classes.red_border
                    } 
                    ${classes.input_with_btn}`}
                    getOptionLabel={componentsList =>
                      componentsList["materialName"]
                    }
                    getOptionValue={componentsList =>
                      componentsList["pkMaterialId"]
                    }
                    onFocus={() =>
                      sets.setIngrediantModalShowComponentErrMsg(false)
                    }
                    onBlur={() =>
                      sets.setIngrediantModalShowComponentErrMsg(true)
                    }
                    styles={{
                      menu: base => ({
                        ...base,
                        backgroundColor:
                          layoutModeType === layoutModeTypes.LIGHT
                            ? selectInputBgColors.light
                            : selectInputBgColors.dark,
                      }),
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    type="button"
                    id="inputGroupFileAddon04"
                    onClick={handleComponentModal}
                  >
                    {props.t("Add")}
                  </button>
                </div>
                {states.ingrediantModalShowComponentErrMsg &&
                  !selectedComponent && (
                    <SelectInputErrorMessage>
                      {errorMsgs.component}
                    </SelectInputErrorMessage>
                  )}
              </div>
            </Col>
            <Col xs={6}>
              <div className="mb-3">
                <Label className="form-label">{props.t("Quantity")}</Label>
                <div className="input-group">
                  <Input
                    name="componentQuantity"
                    type="number"
                    placeholder={props.t("Enter Quantity")}
                    onChange={e => setComponentQuantity(e.target.value)}
                    onBlur={componentValidation.handleBlur}
                    value={componentValidation.values.componentQuantity || ""}
                    invalid={
                      componentValidation.touched.componentQuantity &&
                      componentValidation.errors.componentQuantity
                        ? true
                        : false
                    }
                    min={0}
                  />
                  <button className="btn btn-primary mx-1" type="submit">
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    {props.t("Add Ingrediant")}
                  </button>
                  {componentValidation.touched.componentQuantity &&
                  componentValidation.errors.componentQuantity ? (
                    <FormFeedback type="invalid">
                      {componentValidation.errors.componentQuantity}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
            </Col>
          </Row>
          <div className="mt-4 mx-auto">
            <SimpleBar
              style={{ maxHeight: "195px", width: "75%", margin: "0 auto" }}
            >
              <div className="table-responsive">
                <Table className="table table-nowrap align-middle table-hover mb-0">
                  <tbody>
                    {(tableRows || []).map((tableRow, key) => (
                      <tr key={key}>
                        <td>
                          <h5 className="text-truncate font-size-14 mb-1">
                            {tableRow.MaterialName}
                          </h5>
                        </td>
                        <td>
                          <h5 className="text-truncate font-size-14 mb-1">
                            {tableRow.Qty}
                          </h5>
                        </td>
                        <td style={{ width: "90px" }}>
                          <div>
                            <ul className="list-inline mb-0 font-size-16">
                              <li className="list-inline-item">
                                <Link
                                  onClick={() =>
                                    // handleRemoveRow(tableRow.FK_MaterialID)
                                    handleRemoveRow(tableRow.id)
                                  }
                                  className="text-danger p-1 border-none"
                                >
                                  <i className="bx bxs-trash" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </SimpleBar>
          </div>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-success save-user"
                  onClick={toggle}
                >
                  <i className="mdi mdi-arrow-left-circle me-1" />
                  {props.t("back")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
        <ComponentModal
          componentModal={componentModal}
          handleComponentToggle={handleComponentToggle}
          handleComponentAdded={handleComponentAdded}
        />
        <MaterialModal
          materialModal={materialModal}
          handleMaterialToggle={handleMaterialToggle}
        />
      </ModalBody>
    </Modal>
  )
}

IngrediantModal.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(IngrediantModal))
