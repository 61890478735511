import React, { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import TableContainer from '../../../components/Common/TableContainer';
import {
    Col,
    Row,
    Card,
    CardBody,
    Spinner
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

import { convertDate, convertParametersToString } from "../helpers";
import DateFromTo from "../shared/DateFromTo";
import SelectEmployee from "../shared/SelectEmployee";
import { useSelector, useDispatch } from "react-redux";
import { getExpenseReport } from "store/actions";
import SendAndPrintPdf from "pages/Reports/shared/pdf/components/SendAndPrintPdf";

const ExpenseReport = (props) => {
    document.title = "Expense Report | Reports";
    const dispatch = useDispatch();
    const [date, setDate] = useState(null);
    const [currentEmpId, setcurrentEmpId] = useState("");
    const [currentEmpName, setcurrentEmpName] = useState("");

    const reports = useSelector(state => state.Reports);
    const { getExpenseReport: expenseReport, loadingExpenseReport: loading } = reports;

    useEffect(() => {
        let params = {};
        if (date) {
            params = { ...date };
        }

        if (currentEmpId && currentEmpId !== "All") {
            params.empID = currentEmpId;
        }
        const paramters = convertParametersToString(params);
        dispatch(getExpenseReport(paramters));
    }, [currentEmpId, date]);

    const getDateFromToHandler = (date) => {
        setDate(date);
    };

    const onChangeEmpIdHandler = (pkEmpId, currentEmp) => {
        setcurrentEmpId(pkEmpId);
        setcurrentEmpName(currentEmp);
    };

    const columns = useMemo(
        () => [
            {
                Header: props.t('Date'),
                accessor: 'PayDate',
                filterable: true,
                Cell: (cellProps) => {
                    const date = new Date(cellProps.value);
                    const year = date.getFullYear();
                    const month = date.getMonth() + 1;
                    const day = date.getDate();

                    return <>{cellProps.value ? day + "/" + month + "/" + year : ""}</>;
                }
            },
            {
                Header: props.t('Spending'),
                accessor: 'Description',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
            {
                Header: props.t('Value'),
                accessor: 'Value',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || 0}</>;
                }
            },
            {
                Header: props.t('Employee'),
                accessor: 'EmpName',
                filterable: true,
                Cell: (cellProps) => {
                    return <>{cellProps.value || ""}</>;
                }
            },
        ],
        [props.t]
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs
                        title={props.t("Shift")}
                        breadcrumbItem={props.t("Expense Report")}
                    />
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom ml-2">
                                    <Row className="row-cols-auto gap-4 align-items-center">
                                        <DateFromTo getDateFromTo={getDateFromToHandler} />
                                        <SelectEmployee onChangeEmpId={onChangeEmpIdHandler} />
                                    </Row>
                                </CardBody>
                                <CardBody>
                                    {loading
                                        ? <div className="text-center py-5"><Spinner className="ms-2" color="primary" /></div>
                                        : <TableContainer
                                            columns={columns}
                                            data={expenseReport}
                                            isGlobalFilter={false}
                                            customPageSize={5}
                                            allListSize={expenseReport.length}
                                        />
                                    }
                                </CardBody>
                                {!loading && expenseReport.length > 0 ?
                                    <div className="mt-2 d-flex justify-content-end">
                                        <SendAndPrintPdf
                                            data={expenseReport}
                                            extraData={{
                                                "Date_from": date && date.dateFrom ? convertDate(new Date(date.dateFrom)) : "First",
                                                "Date_to": date && date.dateTo ? convertDate(new Date(date.dateTo)) : convertDate(new Date()),
                                                "Employee": currentEmpName || "All",
                                                "Items_no": expenseReport.length || 0
                                            }}
                                            filteredData={["No", "PayDate", "Description", "Value", "EmpName"]}
                                        />
                                    </div>
                                    : <></>
                                }
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

ExpenseReport.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(ExpenseReport));