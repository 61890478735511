import React from "react";
import { Navigate } from "react-router-dom";


// Authentication related pages
import Login from "../pages/Authentication/Login";

// Comment by asmaa

import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Products by Asmaa
import Products from "../pages/Products/index";

import Purchase from "../pages/Purchase/index";

import Expenses from "pages/Expenses";

import EndShiftReport from "pages/EndShiftReport";


//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import SafeTransfer from "pages/SafeTransfer";
import EndShift from "pages/EndShift";
import StockBalance from "pages/Reports/StockBalance/StockBalance";
import SafeBalance from "pages/Reports/SafeBalance";
import SupplierPayment from "pages/Reports/supplierPayment";
import NetProfit from "pages/Reports/NetProfit";

// new Report pages
import MonthlyReport from "pages/Reports/MonthlyReport";
import DailyReport from "pages/Reports/DailyReport";
import SupplierAccount from "pages/Reports/SupplierAccount";
import ExpenseReport from "pages/Reports/ExpenseReport";
import ItemsSalesReport from "pages/Reports/ItemsSalesReport";
import ShiftReport from "pages/Reports/ShiftReport";

// Cooment By Asmaa

// import PagesMaintenance from "../pages/Utility/pages-maintenance";
// import PagesComingsoon from "../pages/Utility/pages-comingsoon";
// import PagesTimeline from "../pages/Utility/pages-timeline";
// import PagesFaqs from "../pages/Utility/pages-faqs";
// import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
// import Pages500 from "../pages/Utility/pages-500";


const authProtectedRoutes = [
  // { path: "/dashboard", component: <Dashboard /> },
  { path: "/products", component: <Products /> },
  { path: "/purchase", component: <Purchase /> },
  { path: "/expenses", component: <Expenses /> },
  { path: "/safe-transfer", component: <SafeTransfer /> },
  { path: "/end-shift", component: <EndShift /> },
  { path: "/end-shift-report", component: <EndShiftReport /> },

  { path: "/reports/stock-balance", component: <StockBalance /> },
  { path: "/reports/safe-balance", component: <SafeBalance /> },
  { path: "/reports/supplier-payment", component: <SupplierPayment /> },
  { path: "/reports/net-profit", component: <NetProfit /> },
  { path: "/reports/monthly-report", component: <MonthlyReport /> },
  { path: "/reports/daily-report", component: <DailyReport /> },
  { path: "/reports/supplier-account", component: <SupplierAccount /> },
  { path: "/reports/expense-report", component: <ExpenseReport /> },
  { path: "/reports/items-sales-report", component: <ItemsSalesReport /> },
  { path: "/reports/shift-report", component: <ShiftReport /> },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },

  //Comment By Asmaa
  // { path: "/pages-timeline", component: <PagesTimeline /> },
  // { path: "/pages-faqs", component: <PagesFaqs /> },
  // { path: "/pages-pricing", component: <PagesPricing /> },


  {
    path: "/",
    exact: true,
    component: <Navigate to="/products" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },

  // Comment by Asmaa
  // { path: "/logout", component: <Logout /> },
  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },

  // { path: "/pages-maintenance", component: <PagesMaintenance /> },
  // { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  // { path: "/pages-404", component: <Pages404 /> },
  // { path: "/pages-500", component: <Pages500 /> },
  // { path: "/crypto-ico-landing", component: <CryptoIcoLanding /> },
];

const errorRoutes = [
  { path: "*", component: <Pages404 /> },
];

export { authProtectedRoutes, publicRoutes, errorRoutes };
