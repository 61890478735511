import React from "react";

const SelectInputErrorMessage = (props) => {
    const { children } = props;
    return (
        <div style={{
            width: '100%',
            marginTop: '0.25rem',
            fontSize: '80%',
            color: '#f46a6a',
        }}>
            {children}
        </div>
    );
};

export default SelectInputErrorMessage;
