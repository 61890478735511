// export async function get(url, config = {}) {
//     return await axiosApi
//       .get(url, { ...config })
//       .then((response) => response.data);
//   }
import { toast } from "react-toastify";

import axiosAuthInstance from "../axios/axiosAuthInstance";

import axiosAuthInstanceImg from "../axios/axiosAuthInstanceImg";


const unAuthorizedHandler = (err, url) => {
    toast.dismiss();
    toast.error(err.message || "Something went wrong");
    if (err.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("authUser");
        window.location.href = "/login";
    }
};

export async function get(url, config = {}) {
    return await axiosAuthInstance.get(url, { ...config })
        .then((response) => response.data)
        .catch(err => unAuthorizedHandler(err, url));
}

export async function post(url, data, config = {}) {
    return await axiosAuthInstance.post(url, data, config)
        .then((response) => response.data)
        .catch(err => unAuthorizedHandler(err, url));
}

export async function postImg(url, data) {
    try {
        const res = await axiosAuthInstanceImg.post(url, data);
        return res;
    } catch (e) {
        console.log(e);
        unAuthorizedHandler(e);
    }
    // return await axiosAuthInstanceImg.post(url, data)
    //     .then((response) => response.data)
    //     .catch(err => unAuthorizedHandler(err));
}
