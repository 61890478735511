import {
  GET_MATERIALS_SUCCESS,
  GET_MATERIALS_FAIL,
  POST_MATERIAL_SUCCESS,
  POST_MATERIAL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  allMaterials: [],
  // userProfile: {},
  error: {},
}

const materials = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MATERIALS_SUCCESS:
      return {
        ...state,
        allMaterials: action.payload,
      }

    case GET_MATERIALS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case POST_MATERIAL_SUCCESS:
      return {
        ...state,
        allMaterials: [...state.allMaterials, action.payload],
      }

    case POST_MATERIAL_FAIL:
      return {
        ...state,
        error: { ...state, error: action.payload },
      }

    default:
      return state
  }
}

export default materials
