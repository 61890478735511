import {
  GET_TBL_STORE,
  GET_TBL_STORE_SUCCESS,
  GET_TBL_STORE_FAIL,
  GET_TBL_MATERIAL,
  GET_TBL_MATERIAL_SUCCESS,
  GET_TBL_MATERIAL_FAIL,
  GET_TBL_EMP,
  GET_TBL_EMP_SUCCESS,
  GET_TBL_EMP_FAIL,
  GET_STOCK_BALANCE_FILTERED_BY_STOCK,
  GET_STOCK_BALANCE_FILTERED_BY_STOCK_SUCCESS,
  GET_STOCK_BALANCE_FILTERED_BY_STOCK_FAIL,
  GET_STOCK_BALANCE_FILTERED_BY_MATERIAL,
  GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_SUCCESS,
  GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_FAIL,
  GET_SAFE_BALANCE,
  GET_SAFE_BALANCE_SUCCESS,
  GET_SAFE_BALANCE_FAIL,
  GET_NET_PROFIT,
  GET_NET_PROFIT_SUCCESS,
  GET_NET_PROFIT_FAIL,
  GET_DAY_TRANS,
  GET_DAY_TRANS_SUCCESS,
  GET_DAY_TRANS_FAIL,
  GET_MONTH_TRANS,
  GET_MONTH_TRANS_SUCCESS,
  GET_MONTH_TRANS_FAIL,
  GET_SUPPLIER_ACCOUNT,
  GET_SUPPLIER_ACCOUNT_SUCCESS,
  GET_SUPPLIER_ACCOUNT_FAIL,
  GET_ITEMS_SALES,
  GET_ITEMS_SALES_SUCCESS,
  GET_ITEMS_SALES_FAIL,
  GET_EXPENSE_REPORT,
  GET_EXPENSE_REPORT_SUCCESS,
  GET_EXPENSE_REPORT_FAIL,
  GET_SUPPLIER_PAYMENTS,
  GET_SUPPLIER_PAYMENTS_SUCCESS,
  GET_SUPPLIER_PAYMENTS_FAIL,
  GET_SHIFT_REPORT,
  GET_SHIFT_REPORT_SUCCESS,
  GET_SHIFT_REPORT_FAIL
} from "./actionTypes";

// Utils actions
const getTblStore = () => ({
  type: GET_TBL_STORE,
});

const getTblStoreSuccess = tblStore => ({
  type: GET_TBL_STORE_SUCCESS,
  payload: tblStore,
});

const getTblStoreFail = error => ({
  type: GET_TBL_STORE_FAIL,
  payload: error,
});

const getTblMaterial = () => ({
  type: GET_TBL_MATERIAL,
});

const getTblMaterialSuccess = tblMaterial => ({
  type: GET_TBL_MATERIAL_SUCCESS,
  payload: tblMaterial,
});

const getTblMaterialFail = error => ({
  type: GET_TBL_MATERIAL_FAIL,
  payload: error,
});

const getTblEmp = (parameters) => ({
  type: GET_TBL_EMP,
  payload: parameters,
});

const getTblEmpSuccess = tblEmp => ({
  type: GET_TBL_EMP_SUCCESS,
  payload: tblEmp,
});

const getTblEmpFail = error => ({
  type: GET_TBL_EMP_FAIL,
  payload: error,
});

// Main actions
const getStockBalanceFilteredByStock = (parameters) => ({
  type: GET_STOCK_BALANCE_FILTERED_BY_STOCK,
  payload: parameters,
});

const getStockBalanceFilteredByStockSuccess = stockBalance => ({
  type: GET_STOCK_BALANCE_FILTERED_BY_STOCK_SUCCESS,
  payload: stockBalance,
});

const getStockBalanceFilteredByStockFail = error => ({
  type: GET_STOCK_BALANCE_FILTERED_BY_STOCK_FAIL,
  payload: error,
});

const getStockBalanceFilteredByMaterial = (parameters) => ({
  type: GET_STOCK_BALANCE_FILTERED_BY_MATERIAL,
  payload: parameters,
});

const getStockBalanceFilteredByMaterialSuccess = materialBalance => ({
  type: GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_SUCCESS,
  payload: materialBalance,
});

const getStockBalanceFilteredByMaterialFail = error => ({
  type: GET_STOCK_BALANCE_FILTERED_BY_MATERIAL_FAIL,
  payload: error,
});

const getSafeBalance = () => ({
  type: GET_SAFE_BALANCE,
});

const getSafeBalanceSuccess = materialBalance => ({
  type: GET_SAFE_BALANCE_SUCCESS,
  payload: materialBalance,
});

const getSafeBalanceFail = error => ({
  type: GET_SAFE_BALANCE_FAIL,
  payload: error,
});

const getNetProfit = (parameters) => ({
  type: GET_NET_PROFIT,
  payload: parameters,
});

const getNetProfitSuccess = netProfit => ({
  type: GET_NET_PROFIT_SUCCESS,
  payload: netProfit,
});

const getNetProfitFail = error => ({
  type: GET_NET_PROFIT_FAIL,
  payload: error,
});

const getDayTrans = (parameters) => ({
  type: GET_DAY_TRANS,
  payload: parameters,
});

const getDayTransSuccess = dayTrans => ({
  type: GET_DAY_TRANS_SUCCESS,
  payload: dayTrans,
});

const getDayTransFail = error => ({
  type: GET_DAY_TRANS_FAIL,
  payload: error,
});

const getMonthTrans = (parameters) => ({
  type: GET_MONTH_TRANS,
  payload: parameters,
});

const getMonthTransSuccess = monthTrans => ({
  type: GET_MONTH_TRANS_SUCCESS,
  payload: monthTrans,
});

const getMonthTransFail = error => ({
  type: GET_MONTH_TRANS_FAIL,
  payload: error,
});

const getSupplierAccount = (parameters) => ({
  type: GET_SUPPLIER_ACCOUNT,
  payload: parameters,
});

const getSupplierAccountSuccess = supplierAccount => ({
  type: GET_SUPPLIER_ACCOUNT_SUCCESS,
  payload: supplierAccount,
});

const getSupplierAccountFail = error => ({
  type: GET_SUPPLIER_ACCOUNT_FAIL,
  payload: error,
});

const getItemsSales = (parameters) => ({
  type: GET_ITEMS_SALES,
  payload: parameters,
});

const getItemsSalesSuccess = data => ({
  type: GET_ITEMS_SALES_SUCCESS,
  payload: data,
});

const getItemsSalesFail = error => ({
  type: GET_ITEMS_SALES_FAIL,
  payload: error,
});

const getExpenseReport = (parameters) => ({
  type: GET_EXPENSE_REPORT,
  payload: parameters,
});

const getExpenseReportSuccess = data => ({
  type: GET_EXPENSE_REPORT_SUCCESS,
  payload: data,
});

const getExpenseReportFail = error => ({
  type: GET_EXPENSE_REPORT_FAIL,
  payload: error,
});

const getSupplierPayments = (parameters) => ({
  type: GET_SUPPLIER_PAYMENTS,
  payload: parameters,
});

const getSupplierPaymentsSuccess = data => ({
  type: GET_SUPPLIER_PAYMENTS_SUCCESS,
  payload: data,
});

const getSupplierPaymentsFail = error => ({
  type: GET_SUPPLIER_PAYMENTS_FAIL,
  payload: error,
});

const getShiftReport = (parameters) => ({
  type: GET_SHIFT_REPORT,
  payload: parameters,
});

const getShiftReportSuccess = data => ({
  type: GET_SHIFT_REPORT_SUCCESS,
  payload: data,
});

const getShiftReportFail = error => ({
  type: GET_SHIFT_REPORT_FAIL,
  payload: error,
});

export {
  // Utils actions
  getTblStore,
  getTblStoreSuccess,
  getTblStoreFail,
  getTblMaterial,
  getTblMaterialSuccess,
  getTblMaterialFail,
  getTblEmp,
  getTblEmpSuccess,
  getTblEmpFail,
  // Main actions
  getStockBalanceFilteredByStock,
  getStockBalanceFilteredByStockSuccess,
  getStockBalanceFilteredByStockFail,
  getStockBalanceFilteredByMaterial,
  getStockBalanceFilteredByMaterialSuccess,
  getStockBalanceFilteredByMaterialFail,
  getSafeBalance,
  getSafeBalanceSuccess,
  getSafeBalanceFail,
  getNetProfit,
  getNetProfitSuccess,
  getNetProfitFail,
  getDayTrans,
  getDayTransSuccess,
  getDayTransFail,
  getMonthTrans,
  getMonthTransSuccess,
  getMonthTransFail,
  getSupplierAccount,
  getSupplierAccountSuccess,
  getSupplierAccountFail,
  getItemsSales,
  getItemsSalesSuccess,
  getItemsSalesFail,
  getExpenseReport,
  getExpenseReportSuccess,
  getExpenseReportFail,
  getSupplierPayments,
  getSupplierPaymentsSuccess,
  getSupplierPaymentsFail,
  getShiftReport,
  getShiftReportSuccess,
  getShiftReportFail
};