const GET_TBL_SUPPLIER_PAYMENT = "GET_TBL_SUPPLIER_PAYMENT";
const GET_TBL_SUPPLIER_PAYMENT_SUCCESS = "GET_TBL_SUPPLIER_PAYMENT_SUCCESS";
const GET_TBL_SUPPLIER_PAYMENT_FAIL = "GET_TBL_SUPPLIER_PAYMENT_FAIL";

const GET_TBL_SUPPLIER = "GET_TBL_SUPPLIER";
const GET_TBL_SUPPLIER_SUCCESS = "GET_TBL_SUPPLIER_SUCCESS";
const GET_TBL_SUPPLIER_FAIL = "GET_TBL_SUPPLIER_FAIL";
const ADD_NEW_SUPPLIER = "ADD_NEW_SUPPLIER";
const ADD_NEW_SUPPLIER_SUCCESS = "ADD_NEW_SUPPLIER_SUCCESS";
const ADD_NEW_SUPPLIER_FAIL = "ADD_NEW_SUPPLIER_FAIL";

export {
    GET_TBL_SUPPLIER_PAYMENT,
    GET_TBL_SUPPLIER_PAYMENT_SUCCESS,
    GET_TBL_SUPPLIER_PAYMENT_FAIL,
    GET_TBL_SUPPLIER,
    GET_TBL_SUPPLIER_SUCCESS,
    GET_TBL_SUPPLIER_FAIL,
    ADD_NEW_SUPPLIER,
    ADD_NEW_SUPPLIER_SUCCESS,
    ADD_NEW_SUPPLIER_FAIL
};