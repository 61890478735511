import React, { useEffect } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    FormFeedback,
    Input,
    Label,
    Form
} from "reactstrap";
import SimpleBar from "simplebar-react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { useDispatch, useSelector } from "react-redux";
import { getManagerApproval } from "store/end-shift/actions";
import i18n from "i18next";

const ManagerApprovalModal = (props) => {
    const { closeModel, isOpen } = props;
    const dispatch = useDispatch();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            userName: "",
            password: "",
        },
        validationSchema: Yup.object({
            userName: Yup.string().required(i18n.t("Please Enter Your User Name")),
            password: Yup.string().required(i18n.t("Please Enter Your Password")),
        }),
        onSubmit: values => {
            const { password, userName } = values;
            dispatch(getManagerApproval({ password, userName }));
        },
    });

    const shiftDetails = useSelector(state => state.shiftDetails);
    const { managerApproval } = shiftDetails;

    useEffect(() => {
        if (managerApproval.length === 0) return;
        if (managerApproval[0].Response === "Approved") {
            validation.resetForm();
        }
    }, [managerApproval]);

    const toggle = () => {
        closeModel();
    };

    return (
        <Modal style={{ marginTop: "10%" }} isOpen={isOpen} toggle={toggle} size="lg">
            <ModalHeader toggle={toggle} tag="h4">
                {i18n.t("Manager Approval")}
            </ModalHeader>
            <ModalBody>
                <Form
                    className="form-horizontal"
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                    }}
                >
                    <div className="mx-auto">
                        <SimpleBar
                            style={{ width: "75%", margin: "0 auto" }}
                        >
                            <div className="mb-3">
                                <Label className="form-label">{i18n.t("User Name")}</Label>
                                <Input
                                    name="userName"
                                    className="form-control"
                                    placeholder={i18n.t("Enter User Name")}
                                    type="text"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.userName || ""}
                                    invalid={
                                        validation.touched.userName && validation.errors.userName
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.userName && validation.errors.userName ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.userName}
                                    </FormFeedback>
                                ) : null}
                            </div>
                            <div className="mb-3">
                                <Label className="form-label">{i18n.t("Password")}</Label>
                                <Input
                                    name="password"
                                    value={validation.values.password || ""}
                                    type="password"
                                    placeholder={i18n.t("Enter Password")}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                        validation.touched.password &&
                                            validation.errors.password
                                            ? true
                                            : false
                                    }
                                />
                                {validation.touched.password &&
                                    validation.errors.password ? (
                                    <FormFeedback type="invalid">
                                        {validation.errors.password}
                                    </FormFeedback>
                                ) : null}
                            </div>
                        </SimpleBar>
                    </div>
                    <Row>
                        <Col>
                            <div className="text-end mt-2">
                                <button type="submit" className="btn btn-success save-user">
                                    <i className="mdi mdi-check-circle-outline me-1" />
                                    {/* <i className="mdi mdi-loading me-1" /> */}
                                    {i18n.t("Submit")}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default ManagerApprovalModal;
