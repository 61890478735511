export const convertDate = (date) => {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const covertedDate = new Date(date);

    let formattedDate =
        ('0' + covertedDate.getDate()).slice(-2) + ' ' +
        months[covertedDate.getMonth()] + ', ' +
        covertedDate.getFullYear() + ' ' +
        ('0' + covertedDate.getHours()).slice(-2) + ':' +
        ('0' + covertedDate.getMinutes()).slice(-2) + ':' +
        ('0' + covertedDate.getSeconds()).slice(-2);

    return formattedDate;
};
