const GET_TBL_SAFE = "GET_TBL_SAFE";
const GET_TBL_SAFE_SUCCESS = "GET_TBL_SAFE_SUCCESS";
const GET_TBL_SAFE_FAIL = "GET_TBL_SAFE_FAIL";

const ADD_SAFE_TRANSFER = "ADD_SAFE_TRANSFER";
const ADD_SAFE_TRANSFER_SUCCESS = "ADD_SAFE_TRANSFER_SUCCESS";
const ADD_SAFE_TRANSFER_FAIL = "ADD_SAFE_TRANSFER_FAIL";


const UPLOAD_SAFE_TRANSFER_IMAGES = "UPLOAD_SAFE_TRANSFER_IMAGES";
const UPLOAD_SAFE_TRANSFER_IMAGES_SUCCESS = "UPLOAD_SAFE_TRANSFER_IMAGES_SUCCESS";
const UPLOAD_SAFE_TRANSFER_IMAGES_FAIL = "UPLOAD_SAFE_TRANSFER_IMAGES_FAIL";

export {
    GET_TBL_SAFE,
    GET_TBL_SAFE_SUCCESS,
    GET_TBL_SAFE_FAIL,
    ADD_SAFE_TRANSFER,
    ADD_SAFE_TRANSFER_SUCCESS,
    ADD_SAFE_TRANSFER_FAIL,
    UPLOAD_SAFE_TRANSFER_IMAGES,
    UPLOAD_SAFE_TRANSFER_IMAGES_SUCCESS,
    UPLOAD_SAFE_TRANSFER_IMAGES_FAIL
};
