import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { CardBody, Spinner } from "reactstrap";
import TableContainer from "../../../components/Common/TableContainer";
import Select from "react-select";
import { Value, Amount } from "./StockBalanceCol";
import { useSelector, useDispatch } from "react-redux";
import { getStockBalanceFilteredByMaterial } from "store/actions";
import { selectInputBgColors, layoutModeTypes } from "constants/layout";

const FilteredByMaterial = (props) => {
    const dispatch = useDispatch();
    const [materialData, setMaterialData] = useState([]);
    const [selectedmaterial, setSelectedMaterial] = useState({});
    const [currentMaterialId, setcurrentMaterialId] = useState("");

    const layout = useSelector(state => state.Layout);
    const reports = useSelector(state => state.Reports);
    const { layoutModeType } = layout;
    const {
        getTblMaterial: getTblMaterialData,
        getStockBalanceFilteredByMaterial: columnData,
        loadingStockBalanceFilteredByMaterial: loading
    } = reports;

    useEffect(() => {
        const newMaterailData = getTblMaterialData.map(one => ({ value: one.materialName, label: one.materialName, pkMaterialId: one.pkMaterialId }));
        newMaterailData.unshift({ value: "All", label: "All", pkMaterialId: "All" });
        setSelectedMaterial(newMaterailData[0]);
        setMaterialData(newMaterailData);
    }, [getTblMaterialData]);

    useEffect(() => {
        if (currentMaterialId && currentMaterialId !== "All") {
            dispatch(getStockBalanceFilteredByMaterial(`fk_material_id=${currentMaterialId}`));
        } else {
            dispatch(getStockBalanceFilteredByMaterial(''));
        }
    }, [currentMaterialId]);

    const columns = useMemo(
        () => [
            {
                Header: props.t("Stock Name"),
                accessor: "MaterialName",
                filterable: true,
                Cell: cellProps => {
                    return <Value {...cellProps} />;
                },
            },
            {
                Header: props.t("Balance"),
                accessor: columnData.length > 0 && columnData[0].qty ? "qty" : "Qty",
                filterable: true,
                Cell: cellProps => {
                    return <Amount {...cellProps} />;
                },
            },
        ],
        [columnData, props.t]
    );

    return <>
        <CardBody className="border-bottom ml-2">
            <div className="form-group row px-0 align-items-center">
                <label className="col-auto col-form-label px-0">{props.t("Material Name")}</label>
                <div className="col-auto" style={{ width: "220px" }}>
                    <Select
                        value={selectedmaterial}
                        onChange={(selectedOption) => {
                            setSelectedMaterial(selectedOption);
                            setcurrentMaterialId(selectedOption.pkMaterialId);
                        }}
                        options={materialData}
                        className="select2-selection form-control p-0 border-0"
                        styles={{ menu: base => ({ ...base, backgroundColor: layoutModeType === layoutModeTypes.LIGHT ? selectInputBgColors.light : selectInputBgColors.dark }) }}
                    />
                </div>
            </div>
        </CardBody>
        {loading
            ? <div className="text-center py-5"><Spinner className="ms-2" color="primary" /></div>
            : <TableContainer
                columns={columns}
                data={columnData}
                isGlobalFilter={false}
                customPageSize={5}
                allListSize={columnData.length}
            />
        }
    </>;
};

FilteredByMaterial.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(withTranslation()(FilteredByMaterial));
