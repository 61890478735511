import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import Breadcrumbs from "components/Common/Breadcrumb";

import { useDispatch, useSelector } from "react-redux";
import { addExpense } from "store/expense/actions";
import { getTblSafe } from "store/safe-transfer/actions";

import classes from "../Products/index.module.scss";
import { useFormik } from "formik";

const Expenses = (props) => {
  //meta title
  document.title = "Expenses | Buffalo - Admin";

  const [selectedFiles, setselectedFiles] = useState([]);

  const dispatch = useDispatch();
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      otherSafe: "",
      name: "",
      description: "",
      value: "",
      selectedPkSafeId: "",
      file: "",
    },
    validationSchema: Yup.object({
      otherSafe: Yup.string(),
      name: Yup.string().required(props.t("Expense Name is required")),
      description: Yup.string().required(props.t("Expense Note is required")),
      value: Yup.number().required(props.t("Expense Value is required")),
      selectedPkSafeId: Yup.mixed().when('otherSafe', {
        is: (val) => val === 'otherSafe',
        then: Yup.number().required(props.t('Please select a value')),
      }),
      file: Yup.array(),
    }),
    onSubmit: (values) => {
      let bodyData = {
        payName: values.name,
        description: values.description,
        value: values.value,
        fK_Safe_ID: JSON.parse(localStorage.getItem('authUser')).fkSafeId,
        fK_Shift_ID: JSON.parse(localStorage.getItem('shiftID')),
        return_pay: false,
        buyBill: false,
        file: selectedFiles.length === 0 ? null : selectedFiles
      };
      if (values.otherSafe === "otherSafe") bodyData.fK_Safe_ID = +values.selectedPkSafeId;
      dispatch(addExpense(bodyData));
      const storedOtherSafe = validation.values.otherSafe;
      validation.resetForm();
      validation.setFieldValue("otherSafe", storedOtherSafe);
      setselectedFiles([]);
    },
  });

  // Fetch tabel safe data
  useEffect(() => {
    if (validation.values.otherSafe === "cash") return;
    dispatch(getTblSafe());
  }, [validation.values.otherSafe]);

  const tabelData = useSelector((state) => state.safeTransfer.safeList);
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setselectedFiles(files);
    validation.setValues({
      ...validation.values,
      file: files
    });
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleSafe = e => {
    validation.setValues({
      ...validation.values,
      otherSafe: e.target.value
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Expenses")}
            breadcrumbItem={props.t("Add Expenses")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}>
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="name" className="col-form-label col-sm-2">
                        {props.t("Name")}
                      </Label>
                      <Col sm="10">
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder={props.t("Enter Name")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name &&
                              validation.errors.name
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <Label htmlFor="description" className="col-form-label col-sm-2">
                        {props.t("Note")}
                      </Label>
                      <Col sm="10">
                        <Input
                          id="description"
                          name="description"
                          type="textarea"
                          placeholder={props.t("Enter Notes")}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.description || ""}
                          invalid={
                            validation.touched.description &&
                              validation.errors.description
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="value"
                        className="col-form-label col-sm-2"
                      >
                        {props.t("Value")}
                      </label>
                      <Col sm="10">
                        <Input
                          id="value"
                          name="value"
                          type="number"
                          placeholder={props.t("Enter Value")}
                          className="form-control"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.value || ""}
                          invalid={
                            validation.touched.value &&
                              validation.errors.value
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                      <label
                        htmlFor="value"
                        className="col-form-label col-sm-2"
                      >
                        {props.t("Safe")}
                      </label>
                      <Col sm={6}>
                        <div className="">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="safe"
                            id="cash"
                            value="cash"
                            defaultChecked
                            onChange={handleSafe}
                          />
                          <label
                            className="form-check-label px-3"
                            htmlFor="cash"
                          >
                            {props.t("Cash")}
                          </label>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="safe"
                            id="exampleRadios2"
                            value="otherSafe"
                            onChange={handleSafe}
                          />
                          <label
                            className="form-check-label px-3"
                            htmlFor="exampleRadios2"
                          >
                            {props.t("Other Safe")}
                          </label>
                        </div>
                      </Col>
                    </FormGroup>
                    {validation.values.otherSafe === "otherSafe" && <FormGroup row>
                      <Col sm={2}>
                        <label className="col-form-label">
                          {props.t("Select")}
                        </label>
                      </Col>
                      <Col sm={10}>
                        <Input
                          id="selectedPkSafeId"
                          name="selectedPkSafeId"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.selectedPkSafeId || ""}
                          invalid={
                            validation.touched.selectedPkSafeId &&
                              validation.errors.selectedPkSafeId
                              ? true
                              : false
                          }
                        >
                          <option >
                            {props.t("Select")}
                          </option>
                          {tabelData && tabelData.length > 0 && tabelData
                            .filter(one => one.safe_type.toLowerCase() === "visa" || one.safe_type.toLowerCase() === "company")
                            .map((one, i) => (
                              <option value={one.pkSafeId} key={i}>
                                {one.safeName}
                              </option>
                            ))}
                        </Input>
                      </Col>
                    </FormGroup>}

                    <Row className="mb-4">
                      <Label className="col-form-label col-lg-2">
                        {props.t("Attached Files")}
                      </Label>
                      <Col lg="10">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              className={`dropzone ${classes.dropzone_area}`}
                            >
                              <div
                                className={`dz-message needsclick mt-1 ${classes.dz_message_area}`}
                                {...getRootProps()}
                              >
                                <Input type="file" {...getInputProps()}
                                  invalid={
                                    validation.touched.file &&
                                      validation.errors.file
                                      ? true
                                      : false
                                  } />
                                <div
                                  className={`dz-message needsclick mt-1 rounded${classes.dz_message_area}`}
                                >
                                  <div className="mb-1">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>{props.t("Drop files here or click to upload")}</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <h6 className="text-danger">{validation.touched.file &&
                          validation.errors.file && validation.errors.file}</h6>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                    <Row className="justify-content-end">
                      <Col lg="10">
                        <Button type="submit" color="primary">
                          {props.t("Save")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Expenses.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Expenses));