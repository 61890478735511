import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";

import { useFormik } from "formik";
import Select from "react-select";
import classnames from "classnames";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import classes from "../Products/index.module.scss";
import axiosAuthInstance from "../../axios/axiosAuthInstance";
import SupplierModal from "./Modal/SupplierModal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { uploadExpenseImage, tblSupplier, getMaterials } from "store/actions";
import { post, get } from "helpers/axios_auth_api";

const Purchase = (props) => {
  //meta title
  document.title = "Purchases List | Buffalo - Admin";
  const dispatch = useDispatch();

  const [activeTab, setactiveTab] = useState(1);
  const [nextTabActive, setNextTabActive] = useState(false);

  const [passedSteps, setPassedSteps] = useState([1]);

  const [storeList, setStoreList] = useState([]);

  const [paymentList, setPaymentList] = useState([
    { id: 1, label: "Agel", value: "Agel" },
    { id: 2, label: "Cash", value: "Cash" },
  ]);

  const [safeList, setSafeList] = useState([]);

  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [selectStore, setSelectStore] = useState("");
  const [purchaseDate, setPurchaseDate] = useState("");
  const [selectPayment, setSelectPayment] = useState("");
  const [selectSafe, setSelectSafe] = useState("");

  const [selectedItem, setSelectedItem] = useState("");
  const [rawMaterialQty, setRawMaterialQty] = useState("");
  const [rawMaterialPrice, setrawMaterialPrice] = useState("");

  const [firstSelectedItemRaw, setFirstSelectedItemRaw] = useState("");
  const [firstRawMaterialPrice, setFirstRawMaterialPrice] = useState("");
  const [firstRawMaterialQty, setFirstRawMaterialQty] = useState("");

  const [totalPrice, setTotalPrice] = useState(0.0);

  const [addError, setAddError] = useState(false);

  const [paymentChecked, setPaymentChecked] = useState(false);

  const [supplierModal, setSupplierModal] = useState(false);

  const [selectedFiles, setselectedFiles] = useState([]);

  const [shiftId, setShiftId] = useState("");

  const [formRows, setFormRows] = useState([]);

  const [suppliersList, setSuppliersList] = useState([]);
  const [materialList, setMaterialList] = useState([]);

  const suppliers = useSelector(state => state.suppliers.tblSupplier);
  const materials = useSelector(state => state.materials.allMaterials);

  // Get suppliers
  useEffect(() => {
    if (suppliers && !suppliers.length) {
      dispatch(tblSupplier());
    }
  }, [dispatch, suppliers]);

  useEffect(() => {
    if (suppliers) {
      setSuppliersList(suppliers);
    }
  }, [suppliers]);

  // fetch All Material action
  useEffect(() => {
    dispatch(getMaterials());
  }, []);

  useEffect(() => {
    if (materials && materials.length > 0) setMaterialList(materials);
  }, [materials]);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  // GET Store List Data From Api

  const fetchAllStores = React.useCallback(async () => {
    const fetchedStoreData = await get("TblStore/getPage");
    setStoreList(fetchedStoreData.rs.data);
  });

  // GET safe List Data From Api

  const fetchAllSafe = React.useCallback(async () => {
    const fetchedSafeData = await get("TblSafe/getPage");
    setSafeList(fetchedSafeData.rs.data);
  });



  useEffect(() => {
    fetchAllStores();
    fetchAllSafe();
  }, []);

  useEffect(() => {
    setShiftId(JSON.parse(localStorage.getItem("shiftID")));
  }, []);

  function handleSelectedSupplier(selectedSupplier) {
    setSelectedSupplier(selectedSupplier.target.value);
  }

  function handleSelectedStore(selectedStore) {
    setSelectStore(selectedStore.target.value);
  }

  function handleSelectPayment(e) {
    e.preventDefault();
    setSelectPayment(e.target.value);
  }
  function handleSelectSafe(e) {
    setSelectSafe(e.target.value);
  }

  const submitForm = () => {
    validation.handleSubmit();
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      selectedSupplier: selectedSupplier || "",
      invoiceNumber: invoiceNumber || "",
      selectStore: selectStore || "",
      purchaseDate: purchaseDate || "",
      selectPayment: selectPayment || "",
      selectSafe: selectSafe || "",
      paymentChecked: paymentChecked || "",
    },
    validationSchema: Yup.object({
      selectedSupplier: Yup.string().required(props.t("Please select supplier")),
      invoiceNumber: Yup.string().required(props.t("Please enter invoice number")),
      selectStore: Yup.string().required(props.t("Please select store")),
      purchaseDate: Yup.string().required(props.t("Please enter date")),
      selectPayment: Yup.string().when("paymentChecked", {
        is: true,
        then: Yup.string().required(props.t("Field is required")),
      }),
      selectSafe: Yup.string().when("selectPayment", {
        is: 'Cash',
        then: Yup.string().required(props.t("Field is required")),
      }),
    }),
    onSubmit: values => {
      const newPurchaseData = {
        selectedSupplier: values["selectedSupplier"],
        invoiceNumber: values["invoiceNumber"],
        selectStore: values["selectStore"],
        purchaseDate: values["purchaseDate"],
        selectPayment: values["selectPayment"],
        selectSafe: values["selectSafe"],
      };

      setNextTabActive(true);
      toggleTab(activeTab + 1);
    },
  });

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const keyField = "id";

  const resetRow = () => {
    setFirstSelectedItemRaw("");
    setFirstRawMaterialQty("");
    setFirstRawMaterialPrice("");
  };

  const onAddFormRow = () => {
    if (
      firstSelectedItemRaw !== "" &&
      firstRawMaterialQty !== "" &&
      firstRawMaterialQty >= 1 &&
      firstRawMaterialPrice !== "" &&
      firstRawMaterialPrice >= 1
    ) {
      setAddError(false);
      const newMaterialRaw = {
        selectedItem: firstSelectedItemRaw,
        rawMaterialQty: firstRawMaterialQty,
        rawMaterialPrice: firstRawMaterialPrice,
      };
      const modifiedRows = [...formRows];

      modifiedRows.push({ id: modifiedRows.length + 1, ...newMaterialRaw });
      // modifiedRows.unshift({ id: modifiedRows.length + 1 })
      setFormRows(modifiedRows);
      addToTotalPrice(firstRawMaterialQty, firstRawMaterialPrice);

      resetRow();
    } else {
      setAddError(true);
    }
  };

  const onDeleteFormRow = id => {
    var modifiedRows = [...formRows];
    modifiedRows.map(row => row["id"] === id && subFromTotalPrice(row));
    modifiedRows = modifiedRows.filter(x => x["id"] !== id);
    setFormRows(modifiedRows);
  };

  //Raw Details

  const handleSelectItem = e => {
    setSelectedItem(e.target.value);
  };

  const handleRawMaterialQty = e => {
    setRawMaterialQty(e.target.value);
  };

  const handleRawMaterialPrice = e => {
    setrawMaterialPrice(e.target.value);
  };

  const handleFirstSelectItemRaw = e => {
    setFirstSelectedItemRaw(e.target.value);
  };
  const handleFirstRawMaterialQty = e => {
    setFirstRawMaterialQty(e.target.value);
  };
  const handleFirstRawMaterialPrice = e => {
    setFirstRawMaterialPrice(e.target.value);
  };

  const handleSupplierModal = () => {
    setSupplierModal(true);
  };

  const handleSupplierToggle = () => {
    setSupplierModal(!supplierModal);
  };

  const addToTotalPrice = (qty, price) => {
    const newTotalPrice = totalPrice + Number(qty) * Number(price);
    setTotalPrice(newTotalPrice);
  };

  const subFromTotalPrice = item => {
    const newTotalPrice =
      totalPrice - Number(item.rawMaterialQty) * Number(item.rawMaterialPrice);
    setTotalPrice(newTotalPrice);
  };

  const handleMaterialList = () => {
    const buyMaterialList = [];
    for (let i = 0; i < formRows.length; i++) {
      buyMaterialList.push({
        price: Number(formRows[i].rawMaterialPrice),
        qty: Number(formRows[i].rawMaterialQty),
        fkMaterialId: Number(formRows[i].selectedItem),
        fkBuyMaterial: 0,
      });
    }
    return buyMaterialList;
  };
  // Submit last tabPan
  const submitAllForm = async () => {
    let buyMaterial = {
      fkSupplierId: Number(selectedSupplier),
      totalPrice: Number(totalPrice),
      date: purchaseDate,
      billNo: invoiceNumber,
      payment: validation.values.selectPayment === "Cash" ? false : true,
      // fkStoreId: Number(selectStore),
      // fkSafeId: Number(selectSafe),
    };

    paymentChecked && (buyMaterial.fkStoreId = Number(selectStore));
    paymentChecked && (buyMaterial.fkSafeId = Number(selectSafe));

    const allFormData = {
      fK_Shift_ID: Number(shiftId),
      attachmentPath: "",
      buyMaterial: buyMaterial,
      buyMaterialDetails: handleMaterialList(),
    };
    try {
      const response = await post(
        "Admin/SaveBuyMaterial",
        allFormData
      );
      if (response.sts.code === 200) {
        toast.dismiss();
        toast.success(props.t("Purchase data added successfully"));
        toggleTab(activeTab - 1);

        // upload image
        if (selectedFiles && selectedFiles.length > 0) {
          dispatch(
            uploadExpenseImage({
              Expense_ID: response.rs.pkPaymentID,
              file: selectedFiles,
            })
          );
        }
        resetAllForm();
      }
    } catch (e) {
      toast.dismiss();
      toast.error(props.t("Problem occured. Please, try again"));
      throw e;
    }
  };

  const resetAllForm = () => {
    validation.resetForm();
    setSelectedSupplier("");
    setPurchaseDate("");
    setInvoiceNumber("");
    setSelectStore("");
    setSelectPayment("");
    setSelectSafe("");
    setFormRows([]);
    setselectedFiles([]);
    resetRow();
    setTotalPrice(0.0);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Purchase")}
            breadcrumbItem={props.t("Add Purchase")}
          />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1);
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> {props.t("Purchase Data")}
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              nextTabActive && setactiveTab(2);
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> {props.t("Add Raw Material")}
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col sm="6">
                                <Row>
                                  <Label>{props.t("Supplier Name")}</Label>
                                  <Col sm={12}>
                                    <div className="mb-3">
                                      <div className="input-group">
                                        <div className="col-10">
                                          <Input
                                            name="selectedSupplier"
                                            type="select"
                                            onChange={e => {
                                              handleSelectedSupplier(e);
                                            }}
                                            onBlur={validation.handleBlur}
                                            value={
                                              validation.values
                                                .selectedSupplier || ""
                                            }
                                            invalid={
                                              validation.touched
                                                .selectedSupplier &&
                                                validation.errors.selectedSupplier
                                                ? true
                                                : false
                                            }
                                          >
                                            <option value="">
                                              {props.t("Select Supplier Name")}
                                            </option>
                                            {suppliersList.length > 0 &&
                                              suppliersList.map(
                                                (supplier, index) => (
                                                  <option
                                                    key={index}
                                                    value={
                                                      supplier.pkSupplierId
                                                    }
                                                  >
                                                    {supplier.supplierName}
                                                  </option>
                                                )
                                              )}
                                          </Input>
                                          {validation.touched
                                            .selectedSupplier &&
                                            validation.errors.selectedSupplier ? (
                                            <FormFeedback type="invalid">
                                              {
                                                validation.errors
                                                  .selectedSupplier
                                              }
                                            </FormFeedback>
                                          ) : null}
                                        </div>
                                        <div className="col-2">
                                          <button
                                            className="btn btn-primary w-100"
                                            style={{
                                              borderTopLeftRadius: 0,
                                              borderBottomLeftRadius: 0,
                                              padding: "7px 0",
                                            }}
                                            type="button"
                                            onClick={handleSupplierModal}
                                          >
                                            {props.t("Add")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    {props.t("Invoice Number")}
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-lastname-input2"
                                    placeholder={props.t("Enter Invoice Number")}
                                    name="invoiceNumber"
                                    onChange={e =>
                                      setInvoiceNumber(e.target.value)
                                    }
                                    onBlur={validation.handleBlur}
                                    value={validation.values.invoiceNumber}
                                    invalid={
                                      validation.touched.invoiceNumber &&
                                        validation.errors.invoiceNumber
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.invoiceNumber &&
                                    validation.errors.invoiceNumber ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.invoiceNumber}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label>{props.t("Store Name")}</Label>
                                  <Input
                                    name="selectStore"
                                    type="select"
                                    onChange={e => {
                                      handleSelectedStore(e);
                                    }}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.selectStore || ""}
                                    invalid={
                                      validation.touched.selectStore &&
                                        validation.errors.selectStore
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="">
                                      {props.t("Select Store Name...")}
                                    </option>
                                    {storeList.map((store, index) => (
                                      <option
                                        key={index}
                                        value={store.pkStoreId}
                                      >
                                        {store.storeName}
                                      </option>
                                    ))}
                                  </Input>
                                  {validation.touched.selectStore &&
                                    validation.errors.selectStore ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.selectStore}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    {props.t("Purchase Date")}
                                  </Label>
                                  <Input
                                    type="date"
                                    className="form-control"
                                    id="basicpill-lastname-input2"
                                    name="purchaseDate"
                                    onChange={e =>
                                      setPurchaseDate(e.target.value)
                                    }
                                    onBlur={validation.handleBlur}
                                    value={validation.values.purchaseDate || ""}
                                    invalid={
                                      validation.touched.purchaseDate &&
                                        validation.errors.purchaseDate
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.purchaseDate &&
                                    validation.errors.purchaseDate ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.purchaseDate}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <div className="form-check form-switch form-switch-md mb-3">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    value={
                                      validation.values.paymentChecked || ""
                                    }
                                    id="customSwitchsizemd"
                                    onChange={e =>
                                      setPaymentChecked(e.target.checked)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="customSwitchsizemd"
                                  >
                                    {props.t("Payment Options")}
                                  </label>
                                </div>
                              </Col>
                            </Row>
                            {paymentChecked && (
                              <Row>
                                <Col sm="6">
                                  <div className="mb-3">
                                    <Label>{props.t("Payment Method")}</Label>
                                    <Input
                                      name="selectPayment"
                                      type="select"
                                      onChange={e => {
                                        validation.handleChange;
                                        handleSelectPayment(e);
                                      }}
                                      onBlur={validation.handleBlur}
                                      value={
                                        validation.values.selectPayment || ""
                                      }
                                      invalid={
                                        validation.touched.selectPayment &&
                                          validation.errors.selectPayment
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value="">
                                        {props.t("Select Payment Method")}
                                      </option>
                                      {paymentList.map((method, index) => (
                                        <option
                                          key={index}
                                          value={method.value}
                                        >
                                          {method.label}
                                        </option>
                                      ))}
                                    </Input>
                                    {validation.touched.selectPayment &&
                                      validation.errors.selectPayment ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.selectPayment}
                                      </FormFeedback>
                                    ) : null}
                                  </div>
                                </Col>
                                <Col sm="6">
                                  {validation.values.selectPayment === "Cash" ?
                                    <div className="mb-3">
                                      <Label>{props.t("Safe")}</Label>
                                      <Input
                                        name="selectSafe"
                                        type="select"
                                        onChange={e => {
                                          validation.handleChange;
                                          handleSelectSafe(e);
                                        }}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.selectSafe || ""}
                                        invalid={
                                          validation.touched.selectSafe &&
                                            validation.errors.selectSafe
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="">
                                          {props.t("Select Store Name")}
                                        </option>
                                        {safeList.map((safe, index) => (
                                          <option
                                            key={index}
                                            value={safe.pkSafeId}
                                          >
                                            {safe.safeName}
                                          </option>
                                        ))}
                                      </Input>
                                      {validation.touched.selectStore &&
                                        validation.errors.selectSafe ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.selectSafe}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                    : <></>
                                  }
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <div className="mb-3">
                                <Dropzone
                                  onDrop={acceptedFiles => {
                                    handleAcceptedFiles(acceptedFiles);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div
                                      className={`dropzone ${classes.dropzone_area}`}
                                    >
                                      <div
                                        className={`dz-message needsclick mt-2 ${classes.dz_message_area}`}
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                          <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>
                                          {props.t("Drop files here or click to upload")}
                                        </h4>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {selectedFiles.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {f.name}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {f.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    );
                                  })}
                                </div>
                              </div>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div style={{ minHeight: "300px" }}>
                            <Form
                              className="repeater"
                              encType="multipart/form-data"
                            >
                              <Row>
                                <Col sm={4} className="mb-3">
                                  <Input
                                    name="selectedItem"
                                    type="select"
                                    onChange={e => {
                                      handleFirstSelectItemRaw(e);
                                    }}
                                    value={firstSelectedItemRaw}
                                  >
                                    <option value="">
                                      {props.t("Select Material Name")}
                                    </option>
                                    {materialList.length > 0 && materialList.map((rawItem, index) => (
                                      <option
                                        key={index}
                                        value={rawItem.pkMaterialId}
                                      >
                                        {rawItem.materialName}
                                      </option>
                                    ))}
                                  </Input>
                                </Col>

                                <Col sm={3} xs={6} className="mb-3">
                                  <Input
                                    type="number"
                                    id="quantity"
                                    min="0"
                                    className="form-control"
                                    placeholder={props.t("Enter Quantity")}
                                    value={firstRawMaterialQty}
                                    onChange={e => handleFirstRawMaterialQty(e)}
                                  />
                                </Col>

                                <Col sm={3} xs={6} className="mb-3">
                                  <Input
                                    type="number"
                                    id="price"
                                    min="0"
                                    className="form-control"
                                    placeholder={props.t("Enter Price")}
                                    value={firstRawMaterialPrice}
                                    onChange={e =>
                                      handleFirstRawMaterialPrice(e)
                                    }
                                  />
                                </Col>
                                <Col sm={2}>
                                  <div className="text-end">
                                    <input
                                      type="button"
                                      className="btn btn-success mb-3 mt-lg-0"
                                      onClick={onAddFormRow}
                                      value="Add"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {addError && (
                                <Row>
                                  <Col xs={12}>
                                    <span style={{ color: "#cd0000" }}>
                                      {props.t("Please fill all field")}
                                    </span>
                                  </Col>
                                </Row>
                              )}
                              <hr
                                style={{
                                  borderTop: "1px solid #d5d5d5",
                                  margin: "3px 0",
                                }}
                              />
                            </Form>
                            <div
                              style={{ maxHeight: "350px", overflowY: "auto" }}
                            >
                              {(formRows || []).map((formRow, key) => (
                                <Row key={key} style={{ margin: "5px 0" }}>
                                  <Col sm={4} className="mb-3">
                                    <Input
                                      name="selectedItem"
                                      type="select"
                                      onChange={e => {
                                        handleSelectItem(e);
                                      }}
                                      value={formRow.selectedItem}
                                      disabled
                                    >
                                      <option value="">
                                        {props.t("Select Item Name")}
                                      </option>
                                      {materialList.length > 0 && materialList.map((rawItem, index) => (
                                        <option
                                          key={index}
                                          value={rawItem.pkMaterialId}
                                        >
                                          {rawItem.materialName}
                                        </option>
                                      ))}
                                    </Input>
                                  </Col>

                                  <Col sm={3} xs={5} className="mb-3">
                                    <Input
                                      type="number"
                                      id="quantity"
                                      className="form-control"
                                      placeholder={props.t("Enter Quantity...")}
                                      value={formRow.rawMaterialQty}
                                      onChange={e => handleRawMaterialQty(e)}
                                      disabled
                                    />
                                  </Col>

                                  <Col sm={3} xs={5} className="mb-3">
                                    <Input
                                      type="number"
                                      id="price"
                                      className="form-control"
                                      placeholder={props.t("Enter Price")}
                                      value={formRow.rawMaterialPrice}
                                      onChange={e => handleRawMaterialPrice(e)}
                                      disabled
                                    />
                                  </Col>

                                  <Col sm={2} xs={2}>
                                    {/* <div className="d-grid">
                                        <input
                                          type="button"
                                          className="btn btn-primary"
                                          value="Delete"
                                          onClick={() =>
                                            onDeleteFormRow(formRow.id)
                                          }
                                        />
                                      </div> */}
                                    <Link
                                      value="Delete"
                                      onClick={() =>
                                        onDeleteFormRow(formRow.id)
                                      }
                                      className="text-danger p-1 border-none"
                                    >
                                      <i
                                        className="bx bxs-trash"
                                        style={{
                                          padding: "5px",
                                          fontSize: "22px",
                                          lineHeight: "27px",
                                        }}
                                      />
                                    </Link>
                                  </Col>
                                </Row>
                              ))}
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <hr style={{ borderTop: "1px solid #d5d5d5" }} />
                    <div className="actions clearfix">
                      <ul>
                        {activeTab === 2 && (
                          <li style={{ margin: "0 40px", fontWeight: "600" }}>
                            <span style={{ padding: "0 15px" }}>{props.t("Total")}:</span>{" "}
                            <span>{totalPrice}</span>
                          </li>
                        )}
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            {props.t("Previous")}
                          </Link>
                        </li>
                        {activeTab !== 2 ? (
                          <li className="next">
                            <Link to="#" onClick={submitForm}>
                              {props.t("Next")}
                            </Link>
                          </li>
                        ) : (
                          <li className="next">
                            <Button
                              onClick={submitAllForm}
                              disabled={formRows.length === 0}
                              style={
                                formRows.length !== 0
                                  ? {
                                    backgroundColor: "#FB940E",
                                    border: "none",
                                  }
                                  : { backgroundColor: "#fcb961" }
                              }
                            >
                              {props.t("Submit")}
                            </Button>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <SupplierModal
            supplierModal={supplierModal}
            handleSupplierToggle={handleSupplierToggle}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

Purchase.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Purchase));
